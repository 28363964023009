import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation, redirect } from 'react-router-dom';
// CUSTOM HOOKS
import { useHelpers } from '../hooks/useHelpers';
// GOOGLE MAPS
import { Wrapper, Status } from '@googlemaps/react-wrapper';
// AXIOS
import axios from 'axios';
// PARTIALS
import Map from '../partials/map';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function EvaluationsDet() {
  // AUTH, STATE, NAVIGATION
  const { authobj, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // CUSTOM HOOKS
  const { handleSuspension, handleRework, handleAppointment, handleReoplaPopup, geocode_point } = useHelpers();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [evaluation, setEvaluation] = useState(null);
  // DATA - MAPPA
  const [initCoords, setInitCoords] = useState({ lat: 42.850127, lng: 13.6246959 });  
  const [coords, setCoords] = useState({ lat: 42.850127, lng: 13.6246959 });  
  const [center, setCenter] = useState({ lat: 42.850127, lng: 13.6246959 });
  const [zoom, setZoom] = useState(18);
  const [mapKey, setMapKey] = useState(0);
  const [mapOk, setMapOk] = useState(false);
  const [showReopla, setShowReopla] = useState(false);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);
  // FILE
  const [evaluationSnapshots, setEvaluationSnapshots] = useState([]);
  const [inspectionFotoFiles, setInspectionFotoFiles] = useState([]);
  const [inspectionDiffFiles, setInspectionDiffFiles] = useState([]);
  const [inspectionFotoCats, setInspectionFotoCats] = useState([]);

  useEffect(() => {
    setWait(true);

    // Se sto modificando una pratica esistente, carico le informazioni relative
    if (state && state.evaluation_id) {
      axios.post(window.SERVER_URL+'evaluations/get', { id: state.evaluation_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        let tmp_eval = res.data.evaluation;
        setEvaluation(res.data.evaluation);

        // Verifico se almeno un bene ha dei dati Reopla per decidere se mostrare il pulsante
        res.data.evaluation.assets.forEach((a) => {
          if (a.reopla)
            setShowReopla(true);
        });

        // Carico gli snapshot della perizia
        axios.post(window.SERVER_URL+'evaluations/get_files', { evaluation_id: state.evaluation_id, file_type: 'snapshot' }, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }
        }).then(resf => {
          console.log(resf.data);
          setEvaluationSnapshots(resf.data.files);

          // Carico le foto del sopralluogo
          axios.post(window.SERVER_URL+'inspections/get_files', { evaluation_id: state.evaluation_id, file_type: 'foto' }, {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }
          }).then(resf => {
            console.log(resf.data);
            setInspectionFotoFiles(resf.data.files);
            setInspectionFotoCats(resf.data.cats);

            // Carico le difformità del sopralluogo
            axios.post(window.SERVER_URL+'inspections/get_files', { evaluation_id: state.evaluation_id, file_type: 'difformita' }, {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }
            }).then(resf => {
              console.log(resf.data);
              setInspectionDiffFiles(resf.data.files);

              if (tmp_eval && tmp_eval.indirizzo) {
                let tmp_coords = geocode_point(tmp_eval.indirizzo+' '+tmp_eval.civico+' '+tmp_eval.cap+' '+tmp_eval.comune);
                tmp_coords.then(response => {
                  let tmp_lat = response.data.results && response.data.results.length ? response.data.results[0].geometry.location.lat : '';
                  let tmp_lng = response.data.results && response.data.results.length ? response.data.results[0].geometry.location.lng : '';
                  // VEDO SE CI SONO COORDINATE AGGIORNATE SALVATE PER IL SOPRALLUOGO ALTRIMENTI USO QUELLE CALCOLATE
                  tmp_lat = parseFloat(res.data.evaluation && res.data.evaluation.inspection && res.data.evaluation.inspection.lat ? res.data.evaluation.inspection.lat : tmp_lat);
                  tmp_lng = parseFloat(res.data.evaluation && res.data.evaluation.inspection && res.data.evaluation.inspection.lng ? res.data.evaluation.inspection.lng : tmp_lng);
                  setCoords({ lat: tmp_lat, lng: tmp_lng });
                  setCenter({ lat: tmp_lat, lng: tmp_lng });
                  setMapOk(true);
                });
              }

              setWait(false);
            });
          });
        });
      });
    } else {
      setWait(false);
    }
  }, [globalRefresh]);

  const render = (status) => {
    if (status === Status.SUCCESS) {
      return <Map key={mapKey} center={center} zoom={zoom} width={'100%'} height={'400px'} checks={[coords]} noControls noDrag />;
    }
  }

  const viewGoogleMaps = () => {
    if (evaluation && evaluation.inspection)
      window.open('https://www.google.com/maps/@'+evaluation.inspection.lat+','+evaluation.inspection.lng+',12?q='+evaluation.inspection.lat+','+evaluation.inspection.lng);
    else if (evaluation && evaluation.lat && evaluation.lng)
      window.open('https://www.google.com/maps/@'+evaluation.lat+','+evaluation.lng+',12?q='+evaluation.lat+','+evaluation.lng);
    else if (evaluation && evaluation.indirizzo && evaluation.town)
      window.open('https://www.google.it/maps/place/'+evaluation.indirizzo+' '+evaluation.civico+' '+evaluation.cap+' '+evaluation.town.nome);
    else {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Non posso visualizzare la zona su Google Maps perché assenti sia l'indirizzo che le coordinate"
      });
    }
  }

  const handleResolution = (ass_id) => {
    axios.post(window.SERVER_URL+'inspection_differences/get_resolution', { id: ass_id }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      Swal.fire({
        html: `<img src="${process.env.PUBLIC_URL}/gfx/feedback_positivo.png"><br>
                <b>Risoluzione difformità</b><br><br>
                <label>Descrizione difformità</label>
                <p>${res.data.difformita_note ? res.data.difformita_note : ''}</p>
                <label>Indicazioni risoluzione</label>
                <p>${res.data.difformita_indicazioni ? res.data.difformita_indicazioni : ''}</p>
                <label>Costi per la risoluzione</label>
                <p>${res.data.difformita_importo ? res.data.difformita_importo : ''} €</p>
                <br><br>`,
        showDenyButton: false,
        // showCancelButton: true,
        // confirmButtonText: "Salva",
        // cancelButtonText: "Annulla",
        confirmButtonColor: "#12275d",
        cancelButtonColor: "#12275d",
        width: '800px'
      });
    });
  }

  const handleReject = () => {
    if (evaluation) {
      Swal.fire({
        html: `<img src="${process.env.PUBLIC_URL}/gfx/feedback_positivo.png">
              <h2>Conferma rifiuto incarico</h2><br>
              <p>Vuoi confermare il rifiuto dell'incarico per la<br>pratica con <b>ID ${evaluation.id}</b></p>
              `,
        width: '800px',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Sì, lo confermo',
        // denyButtonText: 'Annulla',
        cancelButtonText: 'No, non lo confermo',
        confirmButtonColor: '#12275E',
        cancelButtonColor: '#d33',
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(window.SERVER_URL+'evaluations/reject', { evaluation_id: evaluation.id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            if (res.data.success === true) {
              Swal.fire({
                html: `<img src="${process.env.PUBLIC_URL}/gfx/feedback_positivo.png">
                      <h2>Rifiuto inviato</h2><br>
                      <p>La tua azione è andata a buon fine</p>
                      `,
                width: '800px',
                confirmButtonText: 'Torna alla dashboard',
                confirmButtonColor: '#12275E'
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate('/dashboard');
                }
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Errore',
                text: "Si è verificato un errore e non è stato possibile inviare il tuo rifiuto per l'incarico."
              });
            }
          });
        }
      });
    }
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className={'content '+(evaluation && evaluation.evaluation_type ? evaluation.evaluation_type.descrizione.replace(/\s+/g, '_').toLowerCase() : '')}>
        <Row className="page_title">
          <Col>
            <h1 style={{'textAlign':'left'}}>
              Dettaglio dati pratica: 
              <span className='bordered_info full'>{ evaluation ? evaluation.id : '' }</span>
              <span className='bordered_info'>{ evaluation && evaluation.stato ? evaluation.stato.descrizione : '' }</span>
            </h1>
          </Col>
        </Row>
        <Row className='det_wrapper wizard_step_content'>
          <Col lg={9}>
            <Row>
              <Col>
                { ['superadmin','tec','qual-cont','qual-val'].includes(authobj.user.role.slug) ?
                  <h2>Overview pratica</h2>
                : ['back-a'].includes(authobj.user.role.slug) ?
                  <h2>Riepilogo inserimento pratica</h2>
                : ['back-b'].includes(authobj.user.role.slug) ?
                  <h2>Riepilogo fatturazione pratica</h2>
                : '' }
              </Col>
            </Row>
            { ['back-a','back-b'].includes(authobj.user.role.slug) ?  // MODULO A - DATI GENERALI
              <>
                <Row>
                  <Col>
                    <label className='step_label'>Dati generali</label>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col lg={5}>
                      <label>Riferimento committente</label>
                      <p>{ evaluation && evaluation.titolare ? evaluation.titolare : '-' } <Button className='inverse mini' style={{'marginRight':'40px'}} disabled>Vedi dettaglio</Button></p>
                    </Col>
                    <Col lg={3}>
                      <label>ID Pratica</label>
                      <p>{ evaluation && evaluation.id ? evaluation.id : '-' }</p>
                    </Col>
                    <Col lg={4}></Col>
                  </Row>
                  <Row>
                    <Col lg={5}>
                      <label>Riferimento cliente</label>
                      <p>
                        { evaluation && evaluation.user ? evaluation.user.etichetta : '-' } 
                        { evaluation && evaluation.user ?
                          <Button className='inverse mini' style={{'marginRight':'40px'}} onClick={() => { navigate('/customers/add', {state: {customer_id: evaluation.user.id}}) }}>Vedi dettaglio</Button>
                        : '' }
                      </p>
                    </Col>
                    <Col lg={3}>
                      <label>Tipo di incarico</label>
                      <p>{ evaluation && evaluation.evaluation_type ? evaluation.evaluation_type.descrizione : '-' }</p>
                    </Col>
                    <Col lg={4}></Col>
                  </Row>
                  <Row>
                    <Col lg={5}>
                      <label>Tecnico assegnato</label>
                      <p>
                        { evaluation && evaluation.technician ? evaluation.technician.nome+' '+evaluation.technician.cognome : '-' } 
                        { evaluation && evaluation.technician ?
                          <Button className='inverse mini' style={{'marginRight':'40px'}} onClick={() => { navigate('/technicians/add', {state: {technician_id: evaluation.technician.id}}) }}>Vedi dettaglio</Button>
                        : '' }
                      </p>
                    </Col>
                    <Col lg={3}>
                      <label>N° beni comprensivi</label>
                      <p>{ evaluation && evaluation.assets ? evaluation.assets.length : '-' } { evaluation && evaluation.num_asset ? `(${evaluation.num_asset.F} fabbricati - ${evaluation.num_asset.T} terreni)` : '' }</p>
                    </Col>
                    <Col lg={4}>
                      <label>Area geografica commessa (provincia)</label>
                      <p>{ evaluation && evaluation.province ? evaluation.province.nome : '-' }</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Commessa</label>
                      <p>{ evaluation && evaluation.note ? evaluation.note : '-' }</p>
                    </Col>
                  </Row>
                </div>
              </>
            : '' }
            { ['superadmin','tec','qual-cont','qual-val','back-a'].includes(authobj.user.role.slug) ?   // MODULO C1 - DATI SOPRALLUOGO
              <>
                <Row>
                  <Col>
                    <label className='step_label'>Dati sopralluogo</label>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col>
                      <label className='step_label'>Indirizzo</label>
                      <Row>
                        <Col>
                          <label>Indirizzo</label>
                          <p>{ evaluation ? evaluation.indirizzo : '-' }</p>
                        </Col>
                        <Col>
                          <label>Civico</label>
                          <p>{ evaluation ? evaluation.civico : '-' }</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Città</label>
                          <p>{ evaluation && evaluation.town ? evaluation.town.nome : '-' }</p>
                        </Col>
                        <Col>
                          <label>Frazione / Località</label>
                          <p>{ evaluation ? evaluation.localita : '-' }</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>CAP</label>
                          <p>{ evaluation ? evaluation.cap : '-' }</p>
                        </Col>
                        <Col>
                          <label>Provincia</label>
                          <p>{ evaluation && evaluation.province ? evaluation.province.nome : '-' }</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Stato</label>
                          <p>{ evaluation ? evaluation.nazione : '-' }</p>
                        </Col>
                      </Row>
                      <label className='step_label' style={{'marginTop':'20px'}}>Coordinate inserite</label>
                      <Row>
                        <Col>
                          <label>Latitudine</label>
                          <p>{ evaluation && evaluation.inspection ? evaluation.inspection.lat : (evaluation && evaluation.lat ? evaluation.lat : '-') }</p>
                        </Col>
                        <Col>
                          <label>Longitudine</label>
                          <p>{ evaluation && evaluation.inspection ? evaluation.inspection.lng : (evaluation && evaluation.lng ? evaluation.lng : '-') }</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button onClick={() => viewGoogleMaps()} style={{'marginTop':'20px','float':'left'}}>Vedi su Google Maps</Button>
                        </Col>
                      </Row>
                      <Row style={{'marginTop':'40px'}}>
                        <Col>
                          <label>Descrizione immobile</label>
                          <p>{ evaluation && evaluation.inspection && evaluation.inspection.descrizione_immobile ? evaluation.inspection.descrizione_immobile : '-' }</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Descrizione contesto urbanistico</label>
                          <p>{ evaluation && evaluation.inspection && evaluation.inspection.descrizione_contesto_urbanistico ? evaluation.inspection.descrizione_contesto_urbanistico : '-' }</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      { mapOk ?
                        <Wrapper apiKey={window.GMAPS_APIKEY} render={render}></Wrapper>
                      : '' }
                    </Col>
                  </Row>
                </div>
              </>
            : '' }
            { ['superadmin','tec','qual-cont','qual-val','back-a'].includes(authobj.user.role.slug) ?  // MODULO C2 - DATI DI CONTATTO PER SOPRALLUOGO
              <>
                <Row>
                  <Col>
                    <label className='step_label'>Dati di contatto per sopralluogo</label>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col>
                      <label>Referente per sopralluogo</label>
                      <p>{ evaluation && evaluation.sopralluogo_referente ? evaluation.sopralluogo_referente : '-' }</p>
                    </Col>
                    <Col>
                      <label>E-mail</label>
                      <p>{ evaluation && evaluation.sopralluogo_email ? evaluation.sopralluogo_email : '-' }</p>
                    </Col>
                    <Col>
                      <label>Telefono 1</label>
                      <p>{ evaluation && evaluation.sopralluogo_telefono_1 ? evaluation.sopralluogo_telefono_1 : '-' }</p>
                    </Col>
                    <Col>
                      <label>Telefono 2</label>
                      <p>{ evaluation && evaluation.sopralluogo_telefono_2 ? evaluation.sopralluogo_telefono_2 : '-' }</p>
                    </Col>
                    <Col>
                      <label>Cellulare</label>
                      <p>{ evaluation && evaluation.sopralluogo_cellulare ? evaluation.sopralluogo_cellulare : '-' }</p>
                    </Col>
                  </Row>
                  <Row style={{'marginTop':'20px'}}>
                    <Col>
                      <label>Amministratore - Nome</label>
                      <p>{ evaluation && evaluation.inspection && evaluation.inspection.amministratore_nome ? evaluation.inspection.amministratore_nome : '-' }</p>
                    </Col>
                    <Col>
                      <label>Amministratore - Telefono</label>
                      <p>{ evaluation && evaluation.inspection && evaluation.inspection.amministratore_telefono ? evaluation.inspection.amministratore_telefono : '-' }</p>
                    </Col>
                    <Col>
                      <label>Amministratore - E-mail</label>
                      <p>{ evaluation && evaluation.inspection && evaluation.inspection.amministratore_email ? evaluation.inspection.amministratore_email : '-' }</p>
                    </Col>
                  </Row>
                </div>
              </>
            : ''}
            { ['superadmin','tec','qual-cont','qual-val','back-a'].includes(authobj.user.role.slug) ?  // MODULO C3 - ELENCO FILE A SUPPORTO
              <>
                <Row>
                  <Col>
                    <label className='step_label'>Elenco file a supporto</label>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col className='tab_wrapper'>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th style={{'width':'120px'}}>Azioni</th>
                          </tr>
                        </thead>
                        <tbody>
                          { evaluation && evaluation.files && evaluation.files.length ? evaluation.files.map((f) => 
                            <tr>
                              <td>{ f.nome }</td>
                              <td className="actions">
                                <Button title="Scarica file" onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></Button>
                              </td>
                            </tr>
                          ) : '' }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </div>
              </>
            : ''}
            { ['superadmin','tec','qual-cont','qual-val','back-a'].includes(authobj.user.role.slug) ?  // MODULO C4 - ELENCO BENI DELLA PRATICA
              <div id="eval_det_beni">
                <Row>
                  <Col>
                    <label className='step_label'>Elenco beni della pratica</label>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  { evaluation && evaluation.num_asset.F > 0 ?
                    <>
                      <label><b>Fabbricati</b></label>
                      <Row>
                        <Col className='tab_wrapper'>
                          <Table responsive>
                          <thead>
                            <tr>
                              {/* <th style={{'width':'60px'}}>Tipo</th> */}
                              <th style={{'width':'350px'}}>Comune catastale</th>
                              <th style={{'width':'100px'}}>Sezione</th>
                              <th style={{'width':'100px'}}>Foglio</th>
                              <th style={{'width':'100px'}}>Particella</th>
                              <th>Subalterno</th>
                              <th>Categoria</th>
                            </tr>
                          </thead>
                          <tbody>
                            { evaluation && evaluation.assets && evaluation.assets.length ? evaluation.assets.map((asset) =>
                                asset.parcel_type.sigla == 'F' ? (
                                  <tr key={ asset.id }>
                                    {/* <td>{ asset.parcel_type ? asset.parcel_type.sigla : '-' }</td> */}
                                    <td style={{'width':'350px'}}>{ asset.comune_catastale ? asset.comune_catastale : '-' }</td>
                                    <td style={{'width':'100px'}}>{ asset.sezione ? asset.sezione : '-' }</td>
                                    <td style={{'width':'100px'}}>{ asset.foglio ? asset.foglio : '-' }</td>
                                    <td style={{'width':'100px'}}>{ asset.mappale ? asset.mappale :'-' }</td>
                                    <td>{ asset.subalterno ? asset.subalterno : '-' }</td>
                                    <td>{ asset.registry_category ? asset.registry_category.descrizione : '-' }</td>
                                  </tr>
                                ) : null
                            ) : '' }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  : '' }
                  { evaluation && evaluation.num_asset.T > 0 ?
                    <>
                      <label><b>Terreni</b></label>
                      <Row>
                        <Col className='tab_wrapper'>
                          <Table responsive>
                          <thead>
                            <tr>
                              {/* <th style={{'width':'60px'}}>Tipo</th> */}
                              <th style={{'width':'350px'}}>Comune catastale</th>
                              <th style={{'width':'100px'}}>Sezione</th>
                              <th style={{'width':'100px'}}>Foglio</th>
                              <th style={{'width':'100px'}}>Particella</th>
                              <th></th>
                              {/* <th>Porzione</th> */}
                              {/* <th>Qualità</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            { evaluation && evaluation.assets && evaluation.assets.length ? evaluation.assets.map((asset) =>
                                asset.parcel_type.sigla == 'T' ? (
                                  <tr key={ asset.id }>
                                    {/* <td>{ asset.parcel_type ? asset.parcel_type.sigla : '-' }</td> */}
                                    <td style={{'width':'350px'}}>{ asset.comune_catastale }</td>
                                    <td style={{'width':'100px'}}>{ asset.sezione ? asset.sezione : '-' }</td>
                                    <td style={{'width':'100px'}}>{ asset.foglio ? asset.foglio : '-' }</td>
                                    <td style={{'width':'100px'}}>{ asset.mappale ? asset.mappale : '-' }</td>
                                    <td></td>
                                    {/* <td>{ asset.subalterno }</td> */}
                                    {/* <td>{ asset.registry_category ? asset.registry_category.descrizione : '-' }</td> */}
                                  </tr>
                                ) : null
                            ) : '' }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  : '' }
                </div>
              </div>
            : '' }
            { ['superadmin','tec','qual-cont','qual-val'].includes(authobj.user.role.slug) ?  // MODULO D - RILEVAMENTI FOTOGRAFICI
              <div id="eval_det_fotografie">
                <Row>
                  <Col>
                    <label className='step_label'>Rilevamenti fotografici</label>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col className='tab_wrapper'>
                      { inspectionFotoFiles && inspectionFotoFiles.length ? inspectionFotoFiles.map((f) => 
                        <div className={ f.discarded_at ? 'fotoThumbWrapper discarded' : f.user && f.user.role && f.user.role.slug == 'qual-cont' ? 'fotoThumbWrapper added' : 'fotoThumbWrapper' }>
                          <p>
                            <img src={process.env.PUBLIC_URL+'/gfx/SPF_AssetsIcone-32.png'} style={{'height':'16px'}} />
                            <span className='filename'>{ f.nome }</span>
                          </p>
                          <div className='fotoThumb' style={{'backgroundImage':"url('"+f.link+"')"}}></div>
                          <div className='bottom_bar'>
                            { f.inspection_image_category ?
                              <span style={{'fontSize':'12px','display':'inline-block','fontWeight':'600','marginRight':'10px'}}>{ f.inspection_image_category.descrizione.substr(0, 24)+(f.inspection_image_category.descrizione.length > 24 ? '...' : '') }</span>
                            : '' }
                            <a href="#" style={{'float':'right'}} onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></a>
                          </div>
                        </div>
                      ) : <p>Non sono presenti rilevamenti fotografici</p> }
                    </Col>
                  </Row>
                </div>
              </div>
            : '' }
            { ['superadmin','tec','qual-cont','qual-val'].includes(authobj.user.role.slug) ?  // MODULO D2 - PLANIMETRIE E DIFFORMITA
              <>
                <Row>
                  <Col>
                    { evaluation && evaluation.evaluation_type && ['accertamento_danni','accertamento_danni_elettrici'].includes(evaluation.evaluation_type.descrizione.replace(/\s+/g, '_').toLowerCase()) ?
                      <label className='step_label'>Planimetrie</label>
                    : 
                      <label className='step_label'>Planimetrie e difformità</label> 
                    }
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col className='tab_wrapper'>
                      { inspectionDiffFiles && inspectionDiffFiles.length ? 
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>Nome</th>
                              <th className='eval_det_col_tripletta'>Tripletta catastale</th>
                              <th style={{'width':'120px'}}>Azioni</th>
                            </tr>
                          </thead>
                          <tbody>
                            { inspectionDiffFiles.map((f) => 
                              <tr className={ f.discarded_at ? 'discarded' : f.user && f.user.role && f.user.role.slug == 'qual-cont' ? 'added' : '' }>
                                <td>{ f.nome }</td>
                                <td className='eval_det_col_tripletta'>{ f.asset ? f.asset.foglio+' '+f.asset.mappale+' '+f.asset.subalterno : '-' }</td>
                                <td className="actions">
                                  <Button title="Scarica file" onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></Button>
                                </td>
                              </tr>
                            ) }
                          </tbody>
                        </Table>
                      : <p>Non sono presenti dati su planimetrie e difformità</p> }
                    </Col>
                  </Row>
                </div>
                <div id="eval_det_difformita">
                  <Row style={{'marginTop':'20px'}}>
                    <Col>
                      <label className='step_label'>Riscontro difformità</label>
                    </Col>
                  </Row>
                  <div className='inner_wrapper'>
                    { evaluation && evaluation.num_asset.F > 0 ?
                    <>
                      <label><b>Fabbricati</b></label>
                      <Row>
                        <Col className='tab_wrapper'>
                          { evaluation && evaluation.assets && evaluation.assets.length ? 
                            <Table responsive>
                              <thead>
                                <tr>
                                  {/* <th style={{'width':'50px'}}>Tipo</th> */}
                                  <th style={{'width':'350px'}}>Comune catastale</th>
                                  <th style={{'width':'90px'}}>Sezione</th>
                                  <th style={{'width':'100px'}}>Foglio</th>
                                  <th style={{'width':'100px'}}>Particella</th>
                                  <th style={{'width':'60px'}}>Sub</th>
                                  <th style={{'width':'60px'}}>Cat</th>
                                  <th style={{'width':'100px'}}>Difformità</th>
                                  <th className='eval_det_col_diff' style={{'width':'140px'}}>Tipo</th>
                                  <th className='eval_det_col_diff' style={{'width':'120px'}}>Descrizione risoluzione</th>
                                </tr>
                              </thead>
                              <tbody>
                                { evaluation.assets.map((ass) => 
                                  ass.parcel_type.sigla == 'F' ? (
                                    <tr>
                                      {/* <td>{ ass.parcel_type ? ass.parcel_type.sigla : '-' }</td> */}
                                      <td style={{'width':'350px'}}>{ ass.comune_catastale ? ass.comune_catastale : '-' }</td>
                                      <td style={{'width':'90px'}}>{ ass.sezione ? ass.sezione : '-' }</td>
                                      <td style={{'width':'100px'}}>{ ass.foglio ? ass.foglio : '-' }</td>
                                      <td style={{'width':'100px'}}>{ ass.mappale ? ass.mappale :'-' }</td>
                                      <td style={{'width':'60px'}}>{ ass.subalterno ? ass.subalterno : '-' }</td>
                                      <td style={{'width':'60px'}}>{ ass.registry_category ? ass.registry_category.descrizione : '-' }</td>
                                      <td style={{'width':'100px'}}>{ ass.difformita == '1' ? 'Sì' : (ass.difformita == '0' ? 'No' : '-') }</td>
                                      <td className='eval_det_col_diff' style={{'width':'140px'}}>{ ass.inspection_difference ? ass.inspection_difference.descrizione : '-' }</td>
                                      <td className="actions eval_det_col_diff">
                                        <Button title="Vedi risoluzione" onClick={() => { handleResolution(ass.id) }} className={ !ass.difformita || ass.difformita.toString() !== '1' ? 'disabled' : '' }><DynamicIcon iconName="FaEye" /></Button>
                                      </td>
                                    </tr>
                                  ) : null
                                ) }
                              </tbody>
                            </Table>
                          : '' }
                        </Col>
                      </Row>
                    </>
                    : '' }
                    { evaluation && evaluation.num_asset.T > 0 ?
                    <>
                      <label><b>Terreni</b></label>
                      <Row>
                        <Col className='tab_wrapper'>
                          { evaluation && evaluation.assets && evaluation.assets.length ? 
                            <Table responsive>
                              <thead>
                                <tr>
                                  {/* <th style={{'width':'50px'}}>Tipo</th> */}
                                  <th style={{'width':'350px'}}>Comune catastale</th>
                                  <th style={{'width':'100px'}}>Sezione</th>
                                  <th style={{'width':'100px'}}>Foglio</th>
                                  <th style={{'width':'100px'}}>Particella</th>
                                  <th style={{'width':'120px'}}></th>
                                  <th style={{'width':'100px'}}>Difformità</th>
                                  <th className='eval_det_col_diff' style={{'width':'140px'}}>Tipo</th>
                                  <th className='eval_det_col_diff' style={{'width':'120px'}}>Descrizione risoluzione</th>
                                </tr>
                              </thead>
                              <tbody>
                                { evaluation.assets.map((ass) => 
                                  ass.parcel_type.sigla == 'T' ? (
                                    <tr>
                                      {/* <td>{ ass.parcel_type ? ass.parcel_type.sigla : '-' }</td> */}
                                      <td style={{'width':'350px'}}>{ ass.comune_catastale }</td>
                                      <td style={{'width':'100px'}}>{ ass.sezione ? ass.sezione : '-' }</td>
                                      <td style={{'width':'100px'}}>{ ass.foglio ? ass.foglio : '-' }</td>
                                      <td style={{'width':'100px'}}>{ ass.mappale ? ass.mappale : '-' }</td>
                                      <td style={{'width':'120px'}}></td>
                                      <td style={{'width':'100px'}}>{ ass.difformita == '1' ? 'Sì' : (ass.difformita == '0' ? 'No' : '-') }</td>
                                      <td className='eval_det_col_diff' style={{'width':'140px'}}>{ ass.inspection_difference ? ass.inspection_difference.descrizione : '-' }</td>
                                      <td className="actions eval_det_col_diff">
                                        <Button title="Vedi risoluzione" onClick={() => { handleResolution(ass.id) }} className={ !ass.difformita || ass.difformita.toString() !== '1' ? 'disabled' : '' }><DynamicIcon iconName="FaEye" /></Button>
                                      </td>
                                    </tr>
                                  ) : null
                                ) }
                              </tbody>
                            </Table>
                          : '' }
                        </Col>
                      </Row>
                    </>
                    : '' }
                  </div>
                </div>
              </>
            : '' }
            { ['superadmin','tec','qual-cont','qual-val'].includes(authobj.user.role.slug) ?  // MODULO D3 - RISPOSTE E NOTE AL QUESTIONARIO
              <>
                <Row style={{'marginTop':'20px'}}>
                  <Col>
                    { evaluation && evaluation.evaluation_type && ['desktop'].includes(evaluation.evaluation_type.descrizione.replace(/\s+/g, '_').toLowerCase()) ?
                      <label className='step_label'>Note</label>
                    :
                      <label className='step_label'>Risposte e note al questionario</label>
                    }
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  { evaluation && evaluation.inspection ?
                    <Row>
                      { evaluation && evaluation.inspection.questionario && evaluation.evaluation_type && !['desktop'].includes(evaluation.evaluation_type.descrizione.replace(/\s+/g, '_').toLowerCase()) ?  
                        <Col>
                          <Row style={{'marginBottom':'10px'}}>
                            <Col>
                              <label>Hai avuto accesso a tutti i beni indicati come quelli da periziare?</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              { JSON.parse(evaluation.inspection.questionario)[0] == '1' ? 'Sì' : 'No' }
                            </Col>
                          </Row>
                          <Row style={{'marginBottom':'10px','marginTop':'25px'}}>
                            <Col>
                              <label>Hai potuto fare le foto a tutti gli ambienti?</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              { JSON.parse(evaluation.inspection.questionario)[1] == '1' ? 'Sì' : 'No' }
                            </Col>
                          </Row>
                          <Row style={{'marginBottom':'10px','marginTop':'25px'}}>
                            <Col>
                              <label>Hai potuto prendere le misure reali?</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              { JSON.parse(evaluation.inspection.questionario)[2] == '1' ? 'Sì' : 'No' }
                            </Col>
                          </Row>
                        </Col>
                      : '' }
                      <Col>
                        <label>Note del tecnico</label>
                        <p>{ evaluation.inspection.note ? evaluation.inspection.note : '-' }</p>
                        <label>Note della qualità - controllo</label>
                        <p>{ evaluation.inspection.note_qualita_1 ? evaluation.inspection.note_qualita_1 : '-' }</p>
                        <label>Note della qualità - validazione</label>
                        <p>{ evaluation.inspection.note_qualita_2 ? evaluation.inspection.note_qualita_2 : '-' }</p>
                      </Col>
                    </Row>
                  : <Row><Col className='tab_wrapper'><p>Questionario non ancora disponibile</p></Col></Row> }
                </div>
              </>
            : '' }
            { ['back-b'].includes(authobj.user.role.slug) ?   // MODULO F - DATI CONTRATTUALI
              <>
                <Row>
                  <Col>
                    <label className='step_label'>Dati contrattuali</label>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col>
                      <Row>
                        <Col className="first last">
                          <label>Listino applicato al committente</label>
                          <p>
                            { evaluation && evaluation.prezzo ? evaluation.prezzo.cliente+' €' : '-' }
                            <Button className='inverse mini' style={{'marginRight':'40px'}} disabled>Vedi dettaglio</Button>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="first last">
                          <label>Listino applicato al tecnico</label>
                          <p>
                            { evaluation && evaluation.prezzo ? evaluation.prezzo.tecnico+' €' : '-' }
                            <Button className='inverse mini' style={{'marginRight':'40px'}} disabled>Vedi dettaglio</Button>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <label>Riepilogo fatture e pagamenti</label>
                      <p>
                        -
                        <Button className='inverse mini' style={{'marginRight':'40px'}} disabled>Vedi dettaglio</Button>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Note contrattuali</label>
                      <p>-</p>
                    </Col>
                  </Row>
                </div>
              </>
            : '' }
            { ['superadmin','back-a','back-b'].includes(authobj.user.role.slug) ?   // MODULO B - TACCUINO NOTE
              <>
                <Row style={{'marginTop':'20px'}}>
                  <Col>
                    <label className='step_label'>Taccuino note</label>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col>
                      { evaluation && evaluation.notebooks ?
                        <div style={{'background':'#fff','padding':'10px','borderRadius':'10px'}}>
                          <Table responsive id="tab_taccuino">
                            <tr>
                              <th>Data</th>
                              <th>Utente</th>
                              <th>Sezione</th>
                              <th>Motivazione</th>
                              <th>Note</th>
                            </tr>
                            { evaluation && evaluation.notebooks && evaluation.notebooks.length ? evaluation.notebooks.map((n) =>
                              // n.popup_motif ? 
                              (<tr>
                                <td style={{'width':'180px'}}>
                                  { n.created_at ? n.created_at.substr(0, 10).split("-").reverse().join("/") : '-' }
                                  &nbsp;{ n.created_at ? n.created_at.substr(11, 5) : '' }
                                </td>
                                <td style={{'width':'320px'}}>{ n.user.nome+' '+n.user.cognome }</td>
                                <td>{ n.popup_motif ? n.popup_motif.sezione : '' }</td>
                                <td>{ n.popup_motif ? n.popup_motif.descrizione : '' }</td>
                                <td>{ n.note }</td>
                              </tr>) 
                              // : null
                            ) : '' }
                          </Table>
                        </div>
                      : <p style={{'margin':'0 10px'}}>Non sono presenti note per questa perizia</p> }
                    </Col>
                  </Row>
                </div>
              </>
            : '' }
          </Col>
          <Col>
            {/* COLONNA AZIONI E STATI PRATICA */}

            {/* RIFIUTA INCARICO */}
            { evaluation && evaluation.stato && evaluation.stato.slug && evaluation.stato.slug == "assegnata" && authobj.user.role.slug == "tec" ?
              <Button className='sidebar_action reject' onClick={() => handleReject()}>Rifiuta incarico</Button>
            : '' }

            {/* SOSPENSIONE E DESOSPENSIONE PRATICA  */}
            { ['superadmin','tec','qual-cont','qual-val','back-a'].includes(authobj.user.role.slug) ?
              <>
                { evaluation && evaluation.stato && evaluation.stato.slug && evaluation.stato.slug === 'sospesa' ? 
                  <Button className='sidebar_action rework' onClick={() => handleRework(evaluation.id)}>Rilavora pratica</Button>
                :
                (authobj.user.role.slug != 'qual-cont' || (evaluation && evaluation.quality_cont_id)) && (authobj.user.role.slug != 'qual-val' || (evaluation && evaluation.quality_val_id)) ?
                    <Button className='sidebar_action suspend' onClick={() => handleSuspension(evaluation.id)}>Sospendi pratica</Button>
                  : ''
                }
              </>
            : '' }

            {/* GESTIONE APPUNTAMENTO */}
            { evaluation && evaluation.stato && ['assegnata','soprafis'].includes(evaluation.stato.slug) ?
              <Button className='sidebar_action' onClick={() => handleAppointment(evaluation.id)}>Gestisci appuntamento</Button>
            : '' }

            {/* REOPLA */}
            { showReopla ? 
              <Button className='sidebar_action' onClick={() => handleReoplaPopup(evaluation.id)}>Visualizza dati Reopla</Button>
            : '' }

            {/* STORICO APPUNTAMENTI */}
            <h2 style={{'marginTop':'40px'}}>Storico appuntamenti</h2>
            <div id="eval_app_history">
                { evaluation && evaluation.appointments && evaluation.appointments.length ? 
                  <ul>
                    { evaluation.appointments.map((app) =>
                      <li>
                        <DynamicIcon iconName='FaCalendarDay' /> { app.data_appuntamento } &nbsp; <DynamicIcon iconName='FaClock' /> { app.ora_appuntamento.substr(0,5) }<br />
                        { app.note ?
                          <>
                            <DynamicIcon iconName='FaPencilAlt' /> <i>{ app.note }</i>
                            <br />
                          </>
                        : '' }
                        <DynamicIcon iconName='FaAngleRight' /> {app.deleted_at ? <span style={{'color':'#B13905'}}>Non eseguito</span> : (app.svolto == 1 ? <span style={{'color':'green'}}>Eseguito</span> : <span style={{'color':'green'}}>Da fare</span>)}
                      </li>
                    )}
                  </ul>
                : <p>Non sono presenti appuntamenti per questa pratica</p> }
            </div>

            {/* STORICO STATI */}
            <h2 style={{'marginTop':'40px'}}>Storico stati</h2>
            <div id="wizard_bar" className="vert">
              { evaluation && evaluation.storico_stati ? Object.entries(evaluation.storico_stati).map(([key, stato]) => (
                stato.descrizione ? (
                  <div className={stato.data ? 'step active' : 'step'}>
                    { stato.descrizione } { stato.data ? 'il' : '' }
                    <span className='date'>{ stato.data ? stato.data.substr(0, 10) : '' }</span>
                  </div>
                ) : null
              )) : <p>Non sono presenti stati per questa pratica</p> }
            </div>

            {/* SNAPSHOTS */}
            { ['superadmin'].includes(authobj.user.role.slug) ?
              <>
                <hr />
                <h2 style={{'marginBottom':'20px'}}>Snapshots</h2>
                { evaluationSnapshots && evaluationSnapshots.length ? 
                  <ul className='snapshots_list'>
                    { evaluationSnapshots.map((snap, index) => 
                      <li key={index} className='snapshot_item'>
                        <a href={ snap.link } target="_blank">
                          <span><DynamicIcon iconName='FaCalendar' /> { snap.data_caricamento }</span>
                          <span><DynamicIcon iconName='FaUser' /> { snap.user.nome } { snap.user.cognome }</span>
                          <span><DynamicIcon iconName='MdTimelapse' /> { snap.evaluation_log.evaluation_status.descrizione }</span>
                        </a>
                      </li>
                    ) }
                  </ul>
                : <p>Non sono presenti snapshots</p> }
              </>
            : '' }
          </Col>
        </Row>
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => { navigate('/dashboard', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) }}); }}>
              <DynamicIcon iconName='IoIosArrowBack' /> Torna alle pratiche
            </Button> 
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EvaluationsDet;