import React, { useState, useContext, useEffect, useRef } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// TIMEPICKER
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

export function useHelpers() {
    // AUTH, STATE, NAVIGATION
    const { authobj, setAuthobj, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
    let navigate = useNavigate();
    // REFS
    const timeRef = useRef(null);
    // DATA
    const [appointmentTime, setAppointmentTime] = useState(null);
    // SWEETALERT
    const MySwal = withReactContent(Swal);
    // SPINNER
    const [wait, setWait] = useState(false);

    useEffect(() => {
    }, []);

    const formatTime = (date) => {
      const hours = String(date.getHours()).padStart(2, '0'); // Get hours and pad with zero if needed
      const minutes = String(date.getMinutes()).padStart(2, '0'); // Get minutes and pad with zero
      const seconds = String(date.getSeconds()).padStart(2, '0'); // Get seconds and pad with zero 
      return `${hours}:${minutes}`; // Return formatted string
    }

    function roundToNearest15Minutes(date) {
      const roundedDate = new Date(date);
      const minutes = roundedDate.getMinutes();
      const remainder = minutes % 15;
      if (remainder >= 8) {
        roundedDate.setMinutes(minutes + (15 - remainder));
      } else {
        roundedDate.setMinutes(minutes - remainder);
      }
      roundedDate.setSeconds(0);
      roundedDate.setMilliseconds(0);
      return roundedDate;
    }

    function handleTimeChange(val) {
      timeRef.current = val;
      console.log(val);
      const roundedTime = roundToNearest15Minutes(val);
      setAppointmentTime(roundedTime);
    }

    const updateUserState = (showPopup) => {
        axios.post(window.SERVER_URL+'users/sla_late', {}, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
            console.log(res.data);
            let tmp_authobj = authobj;
            tmp_authobj.is_late = res.data.is_late;
            tmp_authobj.count_late = res.data.count_late;
            setAuthobj(tmp_authobj);
            if (showPopup)
              handleUserState();
        });
    }

    const handleUserState = () => {
      if (authobj.user.role.slug == 'tec') {
        if (authobj.user.stato_note) {
            Swal.fire({
              icon: 'warning',
              title: "Il tuo utente è sospeso",
              html: "<b>"+authobj.user.stato_note+"</b>",
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              // cancelButtonColor: '#d33',
            }).then((result) => {
              navigate('/technicians/add', { state: { technician_id: authobj.user.id } });
            });
        }
    
        if (authobj.is_late > 0) {
            const { value: formValues } = Swal.fire({
                html: `<img src="${process.env.PUBLIC_URL}/gfx/Griglia di ripetizione 4.png"><br>
                        <b>ATTENZIONE!</b><br>
                        <p>Ci sono <b>${authobj.count_late} pratiche</b> a te assegnate che risultano avere delle anomalie.<br>
                        Risolvi la situazione per poter accedere alla dashboard.</p>
                        `,
                width: '800px',
                showDenyButton: false,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: "Vai alle pratiche con anomalie",
                confirmButtonColor: "#B13905",
                denyButtonColor: "#12275d",
                cancelButtonColor: "#12275d"
            }).then((result) => {
                navigate('/dashboard/late');
            });
        }
      }
    }

    const geocode_point = (address) => {
      return axios.get(window.GMAPS_GEOCODE_URL+'address='+address+'&key='+window.GMAPS_APIKEY);
    }

    const getAddressFromCoords = (lat, lng) => {
      return axios.get(window.GMAPS_GEOCODE_URL+'latlng='+lat+','+lng+'&key='+window.GMAPS_APIKEY);
    }

    const handleAppointment = (evaluation_id) => {
        axios.post(window.SERVER_URL+'evaluations/get', { id: evaluation_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          console.log(res.data);
    
          let apps = res.data.evaluation.appointments;
    
          const { value: formValues } = MySwal.fire({
            html: (
              <div>
                <img src={process.env.PUBLIC_URL+'/gfx/Griglia di ripetizione 5.png'} />
                <br />
                <h2>Fissa o gestisci l'appuntamento</h2>
                <br />
                <table id="tab_app">
                  { apps && apps.length ? apps.map((app, index) => 
                    <tr className={index == (apps.length - 1) ? 'last' : ''}>
                      <td style={{'textAlign':'left','width':'120px'}}>{app.data_appuntamento}</td>
                      <td style={{'textAlign':'left'}}>{app.ora_appuntamento.substr(0, 5)}</td>
                      <td style={{'textAlign':'left'}} className="note">{app.note ? app.note : ''}</td>
                      <td style={{'textAlign':'right','width':'200px'}}>{app.deleted_at ? <b style={{'color':'#B13905'}}>Non eseguito</b> : (app.svolto == 1 ? <b style={{'color':'#12275D'}}>Eseguito</b> : <b style={{'color':'green'}}>Da fare</b>)}</td>
                    </tr>
                  ) : '' }
                </table>
                { !res.data.evaluation.appointment || res.data.evaluation.appointment.svolto == 0 ? 
                  <table>
                    <tr>
                      <td className="left">
                        <p>Nome<br /><b>{res.data.evaluation.sopralluogo_referente}</b></p>
                        <p>Telefono<br /><b>{res.data.evaluation.sopralluogo_telefono_1}</b></p>
                        <p>Cellulare<br /><b>{res.data.evaluation.sopralluogo_cellulare}</b></p>
                        <p>E-mail<br /><b>{res.data.evaluation.sopralluogo_email}</b></p>
                      </td>
                      <td className="left">
                        <table style={{'margin':'0','width':'100% !important'}}>
                          <tr style={{'border':'0 !important'}}>
                            <td style={{'paddingLeft':'0 !important'}}>
                              Data<br />
                              <input id="appointment_date" name="appointment_date" type="date" min={new Date().toISOString().slice(0, 10)} /><br />
                            </td>
                            <td style={{'paddingRight':'0 !important'}}>
                              Ora<br />
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker 
                                  views={['hours', 'minutes']} 
                                  ampm={false} 
                                  ampmInClock={false} 
                                  format="HH:mm" 
                                  minutesStep={15} 
                                  minTime={new Date().setHours(7, 0)}
                                  maxTime={new Date().setHours(20, 0)}
                                  value={appointmentTime} 
                                  onChange={(val) => handleTimeChange(val)}
                                  slotProps={{
                                    textField: {
                                      inputProps: {
                                        readOnly: true,
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </td>
                          </tr>
                        </table>
                        Note<br />
                        <textarea id="appointment_note" name="appointment_note"></textarea>
                      </td>
                    </tr>
                  </table>
                : ''}
              </div>
            ),
            width: '800px',
            preConfirm: () => {
              return [
                document.getElementById("appointment_date").value,
                // document.getElementById("appointment_hour").value,
                // appointmentTime,
                document.getElementById("appointment_note").value
              ];
            },
            showDenyButton: (res.data.evaluation.appointment && res.data.evaluation.appointment.svolto == 0 ? true : false),
            showCancelButton: false,
            showConfirmButton: (res.data.evaluation.appointment && res.data.evaluation.appointment.svolto == 1 ? false : true),
            confirmButtonText: "Fissa l'appuntamento",
            denyButtonText: "Segna come svolto",
            confirmButtonColor: "#12275d",
            denyButtonColor: "#12275d",
            cancelButtonColor: "#12275d"
          }).then((result) => {
            // console.log(result);
            // console.log(JSON.stringify(formValues));
            if (result.isConfirmed) {
              console.log("DATA APPUNTAMENTO: "+result.value[0]);
              console.log("ORA APPUNTAMENTO: "+timeRef.current);
              // console.log("ORA APPUNTAMENTO: "+formatTime(appointmentTime));
              console.log("NOTE APPUNTAMENTO: "+result.value[1]);

              // CONTROLLO DATA APPUNTAMENTO
              const appointmentDate = new Date(result.value[0]);
              const hours = timeRef.current.getHours();
              const minutes = timeRef.current.getMinutes();
              appointmentDate.setHours(hours);
              appointmentDate.setMinutes(minutes);
              appointmentDate.setSeconds(0);

              if (appointmentDate > new Date()) {
                // salva appuntamento
                axios.post(window.SERVER_URL+'appointments/save', { evaluation_id, 'data_appuntamento': result.value[0], 'ora_appuntamento': formatTime(timeRef.current), 'note': result.value[1] }, { headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer '+authobj.token
                }}).then(res => {
                  setGlobalRefresh(1);
                  console.log(res.data);
                  if (res.data.success) {
                    Swal.fire({
                      'icon': 'success',
                      'title': 'Appuntamento salvato',
                      'text': 'Nuovo appuntamento registrato correttamente'
                    });
                    setGlobalRefresh();
                  } else {
                    Swal.fire({
                      'icon': 'error',
                      'title': 'Errore salvataggio',
                      'text': "Errore durante il salvataggio dell'appuntamento"
                    });
                  }
                });
              } else {
                Swal.fire({
                  'icon': 'error',
                  'title': 'Data e/o ora errati',
                  'text': "Non puoi impostare l'appuntamento in un momento passato"
                });
              }
            } else if (result.isDenied) {
              console.log(res.data.evaluation.appointment);

              let appointment = res.data.evaluation.appointment;
              const [day, month, year] = appointment.data_appuntamento.split('/').map(Number);
              const [hours, minutes, seconds] = appointment.ora_appuntamento.split(':').map(Number);
              const appointmentDateTime = new Date(year, month - 1, day, hours, minutes, seconds); // month is 0-indexed

              if (appointmentDateTime < new Date()) {
                // fissa come svolto
                axios.post(window.SERVER_URL+'appointments/set_done', { evaluation_id }, { headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer '+authobj.token
                }}).then(res => {
                  console.log(res.data);
                  setGlobalRefresh(1);
                  if (res.data.success) {
                    Swal.fire({
                      'icon': 'success',
                      'title': 'Appuntamento svolto',
                      'text': "L'appuntamento è stato impostato come svolto"
                    }).then((result) => {
                      // getPage(
                      //   state && state.page ? state.page : page,
                      //   state && state.query ? state.query : query
                      // );
                      setGlobalRefresh();
                    });
      
                  } else {
                    Swal.fire({
                      'icon': 'error',
                      'title': 'Errore salvataggio',
                      'text': "Impossibile impostare l'appuntamento come svolto"
                    });
                  }
                });
              } else {
                Swal.fire({
                  'icon': 'error',
                  'title': "Impossibile svolgere l'appuntamento",
                  'text': "Non puoi impostare come svolto un appuntamento che non è trascorso"
                });
              }
            }
          });
        });
    }

    const handleReoplaPopup = (evaluation_id) => {
      axios.post(window.SERVER_URL+'evaluations/get', { id: evaluation_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        let tmp_eval = res.data.evaluation;
        let notShowKeys = ['codice_richiesta','codice_utente','rif','link_visura'];
        MySwal.fire({
          html: (
            <div>
              <h2>REOPLA</h2>
              { tmp_eval.assets.map((ass, i) => 
                <>
                  <div style={{'background':'#eee','padding':'10px 10px 15px','fontSize':'16px','lineHeight':'1','marginBottom':'10px','borderRadius':'15px'}}>
                  <b>Bene interrogato #{i+1}</b><br />
                    Comune: {ass.comune_catastale} | Sezione: {ass.sezione} | Foglio: {ass.foglio} | Mappale: {ass.mappale} | Subalterno: {ass.subalterno}
                  </div>
                  <b>Risposta Reopla bene #{i+1}</b><br />
                  {ass.reopla ?
                    <>
                      <table>
                        { Object.entries(JSON.parse(ass.reopla)).map(([key, val]) =>
                          !notShowKeys.includes(key) ?
                            <tr>
                              <td class="left" style={{'width':'40%','fontSize':'16px','lineHeight':'28px'}}>
                                <span style={{'fontWeight':'600','color':'#000'}}>{key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase())}</span>
                              </td>
                              <td class="left" style={{'fontSize':'16px','lineHeight':'28px'}}>
                                { val instanceof Array ?
                                  val.map((subval) =>
                                    <table style={{'borderBottom':'3px solid #ccc'}}>
                                      { Object.entries(subval).map(([kk, vv]) =>
                                        <tr>
                                          <td style={{'fontSize':'16px','lineHeight':'28px'}}>
                                            <span style={{'fontWeight':'600','color':'#000'}}>{kk.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase())}</span>
                                          </td>
                                          <td style={{'fontSize':'16px','lineHeight':'28px'}}>{vv}</td>
                                        </tr>
                                      )}
                                    </table>
                                  )
                                : val }
                              </td>
                            </tr>
                          : ''
                        )}
                      </table>
                      <button class="btn btn-primary" style={{'marginTop':'20px'}} onClick={() => { window.open(JSON.parse(ass.reopla).link_visura) }}>
                        <DynamicIcon iconName='GrDocumentText' size={22} spaced /> Apri visura online
                      </button>
                    </>
                  : <p>Nessun dato disponibile</p> }
                  <hr />
                </>
              )}
            </div>
          ),
          width: '800px',
          customClass: {
            popup: 'custom-swal-popup'
          },
          showDenyButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        });
      });
    }

    const handleRework = (evaluation_id) => {
      axios.post(window.SERVER_URL+'evaluations/get', { id: evaluation_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
          console.log(res.data);
          let tmp_eval = res.data.evaluation;

          axios.post(window.SERVER_URL+'evaluations/get_unsuspend_users', { evaluation_id: evaluation_id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res_users => {
            let tmp_users = res_users.data;
            const { value: formValues } = Swal.fire({
                html: `<img src="${process.env.PUBLIC_URL}/gfx/Griglia di ripetizione 4.png"><br>
                        <h2>GESTIONE SOSPENSIONE</h2><br>
                        <table><tr>
                            <td class="left" style="width: 50%; line-height: 24px; vertical-align: top;">
                                <b>Owner</b><br>
                                ${tmp_eval.in_carico ? tmp_eval.in_carico.nome : ''} ${tmp_eval.in_carico ? tmp_eval.in_carico.cognome : ''}
                                <br><br>
                                <b>Motivo</b><br>
                                ${tmp_eval.notebooks && tmp_eval.notebooks[0] ? tmp_eval.notebooks[0].popup_motif.descrizione : '-'}
                                <br><br>
                                <b>Note aggiuntive</b><br>
                                ${tmp_eval.notebooks && tmp_eval.notebooks[0] ? tmp_eval.notebooks[0].note : '-'}
                            </td>
                            <td class="left" style="width: 50%; line-height: 24px; vertical-align: top;">
                                <b>Data e ora</b><br>
                                ${tmp_eval.notebooks && tmp_eval.notebooks[0] ? tmp_eval.notebooks[0].created_at.substr(0, 10).split("-").reverse().join("/") : ''} 
                                ${tmp_eval.notebooks && tmp_eval.notebooks[0] ? tmp_eval.notebooks[0].created_at.substr(11, 5) : ''}
                                <br><br>
                                <b>Note per il rework</b><br>
                                <textarea id="rework_note" name="suspension_note" placeholder="Inserisci note aggiuntive"></textarea>
                            </td>
                        </tr>
                        <tr style="${tmp_users.length > 0 ? '' : 'display: none;'}">
                          <td class="left">
                            <b>Modifica operatore:</b>
                          </td><td>
                            <select id="new_uid" name="new_uid" style="margin-top: 20px;">
                              <option value="">- Mantieni operatore precedente -</option>
                              ${tmp_users.length && tmp_users.map((u) => {
                                return `<option value="${u.id}">${u.nome} ${u.cognome}</option>`;
                              })}
                          </td>
                        </tr>
                        </table>
                        <br><br>
                        `,
                width: '800px',
                preConfirm: () => {
                    return [
                        document.getElementById("rework_note").value,
                        document.getElementById("new_uid").value,
                    ];
                },
                showDenyButton: false,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Rilavora pratica",
                cancelButtonText: "Ci ho ripensato",
                confirmButtonColor: "#12275d",
                denyButtonColor: "#12275d",
                cancelButtonColor: "#12275d"
            }).then((result) => {
              if (result.isConfirmed) {
                axios.post(window.SERVER_URL+'evaluations/set_unsuspended', { id: evaluation_id, popup_motif_id: 13, note: result.value[0] }, { headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+authobj.token
                }}).then(res => {
                  console.log(res.data);
                  if (res.data.success) { // se la pratica è stata rimessa in lavorazione correttamente
                    if (result.value[1] != '') {  // se devo riassegnare la pratica
                      axios.post(window.SERVER_URL+'evaluations/assign', { evaluation_id: evaluation_id, user_id: result.value[1] }, { headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Bearer '+authobj.token
                      }}).then(res => {
                        if (res.data.success === true) {  // se l'assegnazione è andata a buon fine
                          Swal.fire({
                            'icon': 'success',
                            'title': 'Perizia rimessa in lavorazione e riassegnata',
                            'text': 'La perizia è stata correttamente rimessa in lavorazione e riassegnata'
                          });      
                        } else {  // se l'assegnazione non è andata a buon fine
                          Swal.fire({
                            'icon': 'danger',
                            'title': 'Errore',
                            'text': "La pratica è stata rimessa in lavorazione ma non è stato possibile riassegnare all'utente scelto"
                          });
                        }
                        setGlobalRefresh();
                        navigate('/dashboard');
                      });
                    } else {
                      Swal.fire({
                          'icon': 'success',
                          'title': 'Perizia rimessa in lavorazione',
                          'text': 'La perizia è stata rimessa in lavorazione correttamente'
                      });
                      setGlobalRefresh();
                      navigate('/dashboard');
                    }
                  } else {
                    Swal.fire({
                        'icon': 'danger',
                        'title': 'Errore',
                        'text': "Impossibile rimettere la perizia in lavorazione"
                    });
                  }
                });
              }
            });
          });
      });
    }

    const handleSuspension = (evaluation_id) => {
      axios.post(window.SERVER_URL+'popup_motifs/list', { sezione: 'evaluation_suspended', evaluation_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
          
        let popup_select = '<select id="sospensione_motivo" name="sospensione_motivo">';
        popup_select += '<option value="">- Seleziona la motivazione -</option>';
        res.data.motifs.forEach((opt) => {
          popup_select += '<option value="'+opt.id+'">'+opt.descrizione+'</option>';
        });
        popup_select += '</select>';

        const { value: formValues } = Swal.fire({
          html: `<img src="${process.env.PUBLIC_URL}/gfx/Griglia di ripetizione 4.png"><br>
                  <b>ATTENZIONE!</b><br>
                  <p>Devi indicare il motivo per cui vuoi sospendere la perizia; descrivi bene la situazione.</p>
                  ${popup_select}                 
                  <textarea id="suspension_note" name="suspension_note" placeholder="Motivazione sospensione"></textarea>
                  <br><br>
                  `,
          width: '800px',
          preConfirm: () => {
            return [
              document.getElementById("sospensione_motivo").value,
              document.getElementById("suspension_note").value
            ];
          },
          showDenyButton: false,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Sospendi la perizia",
          cancelButtonText: "Ci ho ripensato",
          confirmButtonColor: "#12275d",
          denyButtonColor: "#12275d",
          cancelButtonColor: "#12275d"
        }).then((result) => {
          if (result.isConfirmed) {
            // sospendi la perizia
            axios.post(window.SERVER_URL+'evaluations/set_suspended', { id: evaluation_id, popup_motif_id: result.value[0], note: result.value[1] }, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
            console.log(res.data);
            if (res.data.success) {
              Swal.fire({
                'icon': 'success',
                'title': 'Perizia sospesa',
                'text': 'La perizia è stata sospesa correttamente'
              });
              setGlobalRefresh();
              navigate('/dashboard');
            } else {
              Swal.fire({
                'icon': 'danger',
                'title': 'Errore',
                'text': "Impossibile impostare la perizia come sospesa"
              });
            }
            });
          }
        });
      });
    }

    const handleAddAsset = (evaluation_id) => {
      setWait(true);
      axios.post(window.SERVER_URL+'evaluations/get', { id: evaluation_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        let tmp_eval = res.data.evaluation;
        
        // Carico le categorie catastali
        axios.post(window.SERVER_URL+'registry_categories/list', { }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(rescat => {
          let registryCategories = rescat.data.registry_categories;

          // Carico le tipologie di particelle
          axios.post(window.SERVER_URL+'parcel_types/list', { }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(restype => {
            let parcelTypes = restype.data.parcel_types;

            // Carico i comuni
            axios.post(window.SERVER_URL+'towns/list', { }, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(restown => {
              let towns = restown.data.towns;

              let asset_cat_select = '<select id="registry_category_id" name="registry_category_id">';
              asset_cat_select += '<option value="">- Categoria catastale -</option>';
              registryCategories.forEach((cat) => {
                asset_cat_select += '<option value="'+cat.id+'">'+cat.descrizione+'</option>';
              });
              asset_cat_select += '</select>';
          
              let asset_type_select = '<select id="parcel_type_id" name="parcel_type_id" onChange="if(this.value == 1) { document.getElementById(\'td_subalterno\').style.display = \'table-cell\'; } else { document.getElementById(\'td_subalterno\').style.display = \'none\'; }">';
              asset_type_select += '<option value="">- Tipo* -</option>';
              parcelTypes.forEach((cat) => {
                asset_type_select += '<option value="'+cat.id+'">'+cat.descrizione+'</option>';
              });
              asset_type_select += '</select>';

              let town_select = '<select id="town_id" name="town_id">'
              town_select += '<option value="">- Seleziona il comune* -</option>';
              towns.forEach((t) => {
                town_select += '<option value="'+t.id+'" '+(t.id == tmp_eval.town_id ? 'selected' : '')+'>'+t.nome+' ('+t.province.sigla+')</option>';
              });
              town_select += '</select>';

              setWait(false);
          
              Swal.fire({
                html: `<b>AGGIUNGI NUOVO BENE ALLA PERIZIA</b><br>
                        <p><b>Inserisci i dati del bene da aggiungere:</b></p>
                        <table>
                          <tr>
                            <td>${asset_type_select}</td>  
                            <td colspan="3">${town_select}</td>
                          </tr>
                          <tr>
                            <td><input type="text" id="sezione" name="sezione" placeholder="Sezione"></td>
                            <td><input type="text" maxLength="4" id="foglio" name="foglio" placeholder="Foglio*"></td>
                            <td><input type="text" maxLength="5" id="mappale" name="mappale" placeholder="Particella*"></td>
                            <td id="td_subalterno"><input type="number" step="1" id="subalterno" name="subalterno" placeholder="Subalterno"></td>
                            <td style="display: none;">${asset_cat_select}</td>
                          </tr>
                        </table>
                        <br><br>`,
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Salva",
                cancelButtonText: "Annulla",
                confirmButtonColor: "#12275d",
                cancelButtonColor: "#12275d",
                width: '800px',
                preConfirm: () => {
                  return [
                    document.getElementById("town_id").value,   // 0
                    document.getElementById("registry_category_id").value,  // 1
                    document.getElementById("parcel_type_id").value,  // 2
                    document.getElementById("foglio").value,  // 3
                    document.getElementById("mappale").value, // 4
                    document.getElementById("subalterno").value, // 5
                    document.getElementById("sezione").value  // 6
                  ];
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  if (!result.value[0] || !result.value[2] || !result.value[3] || !result.value[4]) {
                    Swal.fire({
                      'icon': 'error',
                      'title': 'Dati mancanti',
                      'text': 'Devi inserire tutti i dati richiesti'
                    }).then(() => {
                      handleAddAsset(evaluation_id);
                    });
                  } else {
                    setWait(true);
                    axios.post(window.SERVER_URL+'assets/add', { 
                      evaluation_id: evaluation_id, 
                      town_id: result.value[0],
                      registry_category_id: result.value[1],
                      parcel_type_id: result.value[2],
                      sezione: result.value[6], 
                      foglio: result.value[3], 
                      mappale: result.value[4], 
                      subalterno: (result.value[2] == 1 && !result.value[5] ? '0' : result.value[5])  // se "Fabbricato" e subalterno non valorizzato, metto 0
                    }, { headers: {
                      'Access-Control-Allow-Origin': '*',
                      'Authorization': 'Bearer '+authobj.token
                    }}).then(res => {
                      setGlobalRefresh(1);
                      setWait(false);

                      if (res.data.success) {
                        Swal.fire({
                          'icon': 'success',
                          'title': 'Bene aggiunto',
                          'text': 'Il bene è stato correttamente aggiunto alla perizia'
                        }).then(() => {
                          // navigate('/dashboard');
                        });
                      } else {
                        Swal.fire({
                          'icon': 'error',
                          'title': 'Errore salvataggio',
                          'text': "Errore durante il salvataggio del bene"
                        });
                      }
                    });
                  }
                }
              });
            });
          });
        });
      });
    }

    const handleExport = (evaluation_id) => {
      window.open(window.SERVER_URL+'export/evaluation_pack?evaluation_id='+evaluation_id+'&token='+authobj.token, '_blank');
    }

    return { handleUserState, updateUserState, handleAppointment, handleSuspension, handleRework, geocode_point, getAddressFromCoords, handleAddAsset, handleReoplaPopup, handleExport };
}