import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation, redirect } from 'react-router-dom';
// CUSTOM HOOKS
import { useHelpers } from '../hooks/useHelpers';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm, useFieldArray } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT FILE UPLOADER
import { FileUploader } from "react-drag-drop-files";
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';
import { RssFeed } from '@mui/icons-material';
import { set } from 'date-fns';

function EvaluationsAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, control, handleSubmit, trigger, getValues, setValue, reset, formState: { errors } } = useForm();
  const { fields: t_fields, append: t_append, prepend: t_prepend, remove: t_remove, swap: t_swap, move: t_move, insert: t_insert } = useFieldArray({
    control,
    name: "t_assets"
  });
  const { fields: f_fields, append: f_append, prepend: f_prepend, remove: f_remove, swap: f_swap, move: f_move, insert: f_insert } = useFieldArray({
    control,
    name: "f_assets"
  });
  // CUSTOM HOOKS
    const { geocode_point, getAddressFromCoords } = useHelpers();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [draft, setDraft] = useState(1);
  const [evaluation, setEvaluation] = useState(null);
  const [step, setStep] = useState(1);
  const [customers, setCustomers] = useState([]);
  const [selectCustomers, setSelectCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [projects, setProjects] = useState([]);
  const [selectProjects, setSelectProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectBillingCustomers, setSelectBillingCustomers] = useState([]);
  const [selectedBillingCustomer, setSelectedBillingCustomer] = useState(null);
  const [technicians, setTechnicians] = useState([]);
  const [towns, setTowns] = useState([]);
  const [selectTowns, setSelectTowns] = useState([]);
  const [selectedTown, setSelectedTown] = useState(null);
  const [evaluationTypes, setEvaluationTypes] = useState([]);
  const [lastStep, setLastStep] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [postalCodes, setPostalCodes] = useState([]);
  const [parcelTypes, setParcelTypes] = useState([]);
  const [registryCategories, setRegistryCategories] = useState([]);
  const [showInvoiceInfo, setShowInvoiceInfo] = useState(0);
  const [showTechInfo, setShowTechInfo] = useState(0);
  const [showBtnInvoiceInfo, setShowBtnInvoiceInfo] = useState(0);
  const [selectedType, setSelectedType] = useState(null);
  // FILE UPLOADER
  const [file, setFile] = useState(null);
  const [evaluationFiles, setEvaluationFiles] = useState([]);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    setWait(true);

    // Carico i clienti
    axios.post(window.SERVER_URL+'customers/list', { params: { 'light': true } }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setCustomers(res.data.customers);
      let select_options = [];
      res.data.customers.map((c) => {
        select_options.push({ value: c.id, label: c.etichetta });
      });
      setSelectCustomers(select_options);
      setSelectBillingCustomers(select_options);

      // Carico i progetti
      axios.post(window.SERVER_URL+'projects/list', { }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        setProjects(res.data.projects);
        let select_options = [];
        select_options.push({ value: null, label: '- Nessun progetto -' });
        res.data.projects.map((p) => {
          select_options.push({ value: p.id, label: p.codice });
        });
        setSelectProjects(select_options);

        // Carico i comuni
        axios.post(window.SERVER_URL+'towns/list', { }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setTowns(res.data.towns);
          let select_options = [];
          res.data.towns.map((t) => {
            select_options.push({ value: t.id, label: t.nome+' ('+t.province.sigla+')' });
          });
          setSelectTowns(select_options);

          // Carico le tipologie di pratiche
          axios.post(window.SERVER_URL+'evaluation_types/list', { }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(resty => {
            setEvaluationTypes(resty.data.evaluation_types);

            // Carico le province
            axios.post(window.SERVER_URL+'provinces/list', { }, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
              setProvinces(res.data.provinces);

              // Carico i CAP
              axios.post(window.SERVER_URL+'postal_codes/list', { }, { headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }}).then(res => {
                setPostalCodes(res.data.postal_codes);

                // Carico le tipologie di particelle
                axios.post(window.SERVER_URL+'parcel_types/list', { }, { headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer '+authobj.token
                }}).then(res => {
                  setParcelTypes(res.data.parcel_types);

                  // Carico le categorie catastali
                  axios.post(window.SERVER_URL+'registry_categories/list', { }, { headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+authobj.token
                  }}).then(res => {
                    setRegistryCategories(res.data.registry_categories);

                    // Se sto modificando una pratica esistente, carico le informazioni relative
                    if (state && state.evaluation_id) {
                      axios.post(window.SERVER_URL+'evaluations/get', { id: state.evaluation_id }, { headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Bearer '+authobj.token
                      }}).then(res => {
                        console.log(res.data);
                        setEvaluation(res.data.evaluation);

                        // Carico i tecnici
                        axios.post(window.SERVER_URL+'technicians/list', { params: { evaluation_id: state.evaluation_id } }, { headers: {
                          'Access-Control-Allow-Origin': '*',
                          'Authorization': 'Bearer '+authobj.token
                        }}).then(rest => {
                          setTechnicians(rest.data.technicians);

                          // Carico i file dell'attività
                          axios.post(window.SERVER_URL+'evaluations/get_files', { evaluation_id: state.evaluation_id }, {
                            headers: {
                              'Access-Control-Allow-Origin': '*',
                              'Authorization': 'Bearer '+authobj.token
                            }
                          }).then(resf => {
                            console.log(resf.data);
                            setEvaluationFiles(resf.data.files);

                            // Carico i valori delle tendine filtrate
                            if (res.data.evaluation.user_id) {  // Se ho un cliente selezionato
                              setWait(true);
                              // Carico i progetti
                              axios.post(window.SERVER_URL+'projects/list', { user_id: res.data.evaluation.user_id }, { headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Authorization': 'Bearer '+authobj.token
                              }}).then(res => {
                                console.log(res.data);
                                setProjects(res.data.projects);
                                let select_options = [];
                                select_options.push({ value: null, label: '- Nessun progetto -' });
                                res.data.projects.map((p) => {
                                  select_options.push({ value: p.id, label: p.codice });
                                });
                                setSelectProjects(select_options);
                              });
                            }
                            if (res.data.evaluation.province_id) { // Se ho una provincia selezionata
                              // Carico i comuni
                              axios.post(window.SERVER_URL+'towns/list', { province_id: res.data.evaluation.province_id }, { headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Authorization': 'Bearer '+authobj.token
                              }}).then(res => {
                                let select_options = [];
                                res.data.towns.map((t) => {
                                  select_options.push({ value: t.id, label: t.nome+' ('+t.province.sigla+')' });
                                });
                                setSelectTowns(select_options);
                              });
                            }
                            if (res.data.evaluation.town_id) {  // Se ho un comune selezionato
                              // Carico i CAP
                              axios.post(window.SERVER_URL+'postal_codes/list', { town_id: res.data.evaluation.town_id }, { headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Authorization': 'Bearer '+authobj.token
                              }}).then(res => {
                                setPostalCodes(res.data.postal_codes);
                              });
                            }
                          
                            setTimeout(() => {
                              // Setto le select
                              if (res.data.evaluation.user_id)
                                setSelectedCustomer({ value: res.data.evaluation.user_id, label: res.data.evaluation.user.etichetta });
                              if (res.data.evaluation.project_id)
                                setSelectedProject({ value: res.data.evaluation.project_id, label: res.data.evaluation.project.codice });
                              if (res.data.evaluation.billing_user_id)
                                setSelectedBillingCustomer({ value: res.data.evaluation.billing_user_id, label: res.data.evaluation.billing_user.etichetta });
                              if (res.data.evaluation.town_id)
                                setSelectedTown({ value: res.data.evaluation.town_id, label: res.data.evaluation.town.nome+' ('+res.data.evaluation.town.province.sigla+')' });

                              // Setto i campi della form
                              let xfields = {};
                              for (const [key, val] of Object.entries(res.data.evaluation))
                                if (typeof val !== 'object')
                                  xfields['evaluation['+key+']'] = val;
                              // Setto i campi della form di fatturazione
                              if (res.data.evaluation.billing) {
                                for (const [key, val] of Object.entries(res.data.evaluation.billing))
                                  if (typeof val !== 'object')
                                    xfields['billing['+key+']'] = val;
                              }
                              reset(xfields);

                              // Setto la variabile state del tipo pratica (altrimenti la tendina è valorizzata ma finché non cambio valore lo state rimane vuoto)
                              if (res.data.evaluation.evaluation_type_id) { // se avevo selezionato una tipologia
                                resty.data.evaluation_types.forEach((type) => {
                                  if (type.id == res.data.evaluation.evaluation_type_id)
                                    setSelectedType(type.descrizione);
                                });
                              }
                              // Setto i campi dei beni (assets)
                              if (res.data.evaluation && res.data.evaluation.assets && res.data.evaluation.assets.length) {
                                res.data.evaluation.assets.map((item, index) => {
                                  if (item.parcel_type.sigla == 'F')
                                    f_insert(index, item);
                                  else
                                    t_insert(index, item);
                                });
                              }
                              setWait(false);
                            }, 1000);

                            setWait(false);
                          });
                        });
                      });
                    } else {
                      setWait(false);
                    }
                  });
                });
              });
            });
          });
        });
      });
    });
  }, [reset]);

  const add_t_asset = () => {
    t_append({
      town_id: (selectedTown ? selectedTown.value : null),
      parcel_type_id: null,
      registry_category_id: null,
      sezione: null,
      foglio: null,
      mappale: null,
      subalterno: null,
      note: null,
    });
  }

  const add_f_asset = () => {
    f_append({
      town_id: (selectedTown ? selectedTown.value : null),
      parcel_type_id: null,
      registry_category_id: null,
      sezione: null,
      foglio: null,
      mappale: null,
      subalterno: null,
      note: null,
    });
  }

  const handleFileUpload = (files) => {
    console.log(files);
    if ((evaluation && evaluation.id) || getValues('id')) {
      setWait(true);

      // Carico i files
      const formData = new FormData();
      var readed_files = [];
      // Ciclo sui vari file
      const promises = [];
      Array.from(files).forEach(function(f, idx) {
        promises.push(new Promise((resolve, reject) => {
          var fileReader = new FileReader();
          fileReader.onload = function(evt) {
            let readed = {
              'metadata': {
                'name': f.name,
                'type': f.type,
                'size': f.size
              },
              'content': evt.target.result
            };
            readed_files.push(readed);
            resolve(readed);
          };
          fileReader.onerror = reject;
          fileReader.readAsDataURL(f);
        }));
      });

      Promise.all(promises).then((results) => {
        formData.append('uploads', JSON.stringify(results));
        formData.append('evaluation_id', (evaluation ? evaluation.id : getValues('id')));
        axios.post(window.SERVER_URL+'evaluations/upload', formData, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token,
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          if (res.data.success == true) {
            setWait(false);
            Swal.fire({
              icon: 'success',
              title: 'Caricamento completato',
              html: '<b>'+res.data.log.length+' file</b> sono stati caricati correttamente'
            }).then((result) => {
              if (result.isConfirmed) {
                // Recupero la lista aggiornata dei files
                setWait(true);
                axios.post(window.SERVER_URL+'evaluations/get_files', { evaluation_id: (evaluation ? evaluation.id : getValues('id')) }, {
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+authobj.token
                  }
                }).then(reslist => {
                  console.log(reslist.data);
                  setEvaluationFiles(reslist.data.files);
                  setWait(false);
                });
              }
            });
          } else {
            let log = "";
            res.data.log.forEach(function(row, idx) {
              log += row.name+': '+(row.result ? 'caricato' : 'fallito')+'<br>'
            });
            Swal.fire({
              icon: res.data.log.length ? 'warning' : 'error',
              title: res.data.log.length ? 'Uno o più caricamenti sono falliti' : 'Caricamento dei file fallito',
              html: log
            });
          }
        });
      });

      setWait(false);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: 'Devi salvare la pratica o selezionare almeno il cliente prima di caricare dei file'
      });
    }
  };

  const handleDeleteFile = (id_file) => {
    Swal.fire({
      title: '<strong>Sei sicuro di voler cancellare questo file?</strong>',
      html: "<p>L'operazione non è reversibile</p>",
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'ELIMINA',
      denyButtonText: 'ANNULLA',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(window.SERVER_URL+'evaluations/delete_file', { id: id_file }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          if (res.data.success === true) {
            setEvaluationFiles(evaluationFiles.filter(f => f.id !== id_file));
            Swal.fire({
              icon: 'success',
              title: 'File cancellato',
              // text: 'Il movimento è stato cancellato',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Errore',
              text: 'Si è verificato un problema nella cancellazione'
            });
          }
        });
      }
    });
  }

  const showFattInfo = () => {
    setShowInvoiceInfo(1);
    setShowBtnInvoiceInfo(1);
  }

  const hideFattInfo = () => {
    setShowInvoiceInfo(0);
    setShowBtnInvoiceInfo(0);
  }

  const toggleFattInfo = () => {
    setShowInvoiceInfo(!showInvoiceInfo);
  }

  const toggleTechInfo = () => {
    if (evaluation && evaluation.id && evaluation.province_id) {
      if (!showTechInfo) {
        // Carico i tecnici
        axios.post(window.SERVER_URL+'technicians/list', { params: { evaluation_id: evaluation.id } }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setTechnicians(res.data.technicians);
        });
      }

      setShowTechInfo(!showTechInfo);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Per assegnare a un tecnico devi selezionare il cliente committente e la provincia nella geolocalizzazione"
      });
    }
  }

  const createEvaluation = (e) => {
    setWait(true);
    setSelectedCustomer(e);

    if (!getValues('id')) { // Se non ho già una pratica avviata
      let init_form_data = {};
      init_form_data.evaluation = {};
      init_form_data['evaluation']['user_id'] = e.value;
      axios.post(window.SERVER_URL+'evaluations/save', init_form_data, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token,
        }
      }).then(res => {
        if (res.data.success) {
          setValue('id', res.data.evaluation_id);
          console.log('ID PERIZIA ATTUALE: '+getValues('id'));
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Errore',
            text: "Non è stato possibile creare la pratica"
          });
        }
        setWait(false);
      });
    }

    // Filtro i progetti del cliente
    setWait(true);
    setSelectedProject(null);
    axios.post(window.SERVER_URL+'projects/list', { user_id: e.value }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setProjects(res.data.projects);
      let select_options = [];
      select_options.push({ value: null, label: '- Nessun progetto -' });
      res.data.projects.map((p) => {
        select_options.push({ value: p.id, label: p.codice });
      });
      setSelectProjects(select_options);

      setWait(false);
    });
  }

  const viewCoordsOnGoogleMaps = () => {
    const values = getValues();
    if (values.evaluation.lat && values.evaluation.lng)
      window.open('https://www.google.com/maps/@'+values.evaluation.lat+','+values.evaluation.lng+',12?q='+values.evaluation.lat+','+values.evaluation.lng);
    else {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Devi inserire le coordinate per poterle vedere su Google Maps"
      });
    }
  }

  const viewAddressOnGoogleMaps = () => {
    const values = getValues();
    if (values.evaluation.indirizzo && selectedTown.label)
      window.open('https://www.google.it/maps/place/'+values.evaluation.indirizzo+' '+values.evaluation.civico+' '+values.evaluation.cap+' '+selectedTown.label);
    else {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Devi inserire almeno indirizzo e città per poter trovare la zona su Google Maps"
      });
    }
  }

  const handleChangeType = (e) => {
    console.log(e);
    let selected_label = e.target.label ? e.target.label : e.target.options[e.target.selectedIndex].label;
    // setSelectedType(selected_label);
    setSelectedType((prev) => (prev === selected_label ? prev : selected_label)); // Update state only if the value has changed
  }

  const handleProvinceChange = (province_id) => {
    setWait(true);
    axios.post(window.SERVER_URL+'towns/list', { province_id }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      let select_options = [];
      res.data.towns.map((t) => {
        select_options.push({ value: t.id, label: t.nome+' ('+t.province.sigla+')' });
      });
      setSelectTowns(select_options);
      setSelectedTown(null);
      setPostalCodes([]);
      setValue('evaluation[indirizzo]', '');
      setValue('evaluation[civico]', '');
      setValue('evaluation[lat]', '');
      setValue('evaluation[lng]', '');
      setWait(false);
    });
  }

  const handleTownChange = (selected_town) => {
    console.log(selected_town);
    setSelectedTown(selected_town);
    setWait(true);
    axios.post(window.SERVER_URL+'postal_codes/list', { town_id: selected_town.value }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setPostalCodes(res.data.postal_codes);
      if (res.data.postal_codes.length == 1) {
        setTimeout(function() {
          setValue('evaluation[cap]', res.data.postal_codes[0]);
        }, 1000);
      } else {
        setValue('evaluation[cap]', '');
      }
      setWait(false);
    });
  }

  const handleFindCoords = () => {
    let indirizzo = getValues('evaluation.indirizzo');
    let civico = getValues('evaluation.civico');
    let cap = getValues('evaluation.cap');
    let town = selectedTown ? selectedTown.label : null;
    if (indirizzo && civico && cap && town) {
      setWait(true);
      geocode_point(getValues('evaluation.indirizzo')+' '+getValues('evaluation.civico')+' '+getValues('evaluation.cap')+' '+selectedTown.label).then((res) => {
        console.log(res);
        setWait(false);
        if (res && res.data && res.data.results && res.data.results.length) {
          setValue('evaluation[lat]', res.data.results[0].geometry.location.lat);
          setValue('evaluation[lng]', res.data.results[0].geometry.location.lng);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Errore',
            text: "Non è stato possibile trovare le coordinate"
          });
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Devi inserire almeno indirizzo, civico, CAP e città per poter trovare le coordinate"
      });
    }
  }
  
  const handleFindAddress = () => {
    setWait(true);
    
    let lat = getValues('evaluation[lat]');
    let lng = getValues('evaluation[lng]');
    if (lat && lng) {
      // 1. REINIZIALIZZO LE TENDINE DI PROVINCIA, CITTÀ E CAP
      // Carico i comuni
      axios.post(window.SERVER_URL+'towns/list', { }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setTowns(res.data.towns);
        let select_options = [];
        res.data.towns.map((t) => {
          select_options.push({ value: t.id, label: t.nome+' ('+t.province.sigla+')' });
        });
        setSelectTowns(select_options);

        // Carico le province
        axios.post(window.SERVER_URL+'provinces/list', { }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setProvinces(res.data.provinces);

          // Carico i CAP
          axios.post(window.SERVER_URL+'postal_codes/list', { }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            setPostalCodes(res.data.postal_codes);

            // 2. CERCO DI RECUPERARE L'INDIRIZZO DALLE COORDINATE
            getAddressFromCoords(lat, lng).then((res) => {
              console.log(res);
              if (res && res.data && res.data.results && res.data.results.length) {
                res.data.results[0].address_components.forEach((comp) => {
                  // setto il CAP
                  if (comp.types.includes('postal_code'))
                    setValue('evaluation[cap]', comp.long_name);
                  // setto l'indirizzo
                  if (comp.types.includes('route'))
                    setValue('evaluation[indirizzo]', comp.long_name);
                  // setto il civico
                  if (comp.types.includes('street_number'))
                    setValue('evaluation[civico]', comp.long_name);
                  // setto la provincia
                  if (comp.types.includes('administrative_area_level_2')) {
                    provinces.map((prov) => {
                      if (prov.sigla == comp.short_name)
                        setValue('evaluation[province_id]', prov.id); // il valore del campo devo settarlo a mano perché è una select semplice
                    });
                  }
                  // setto la città
                  if (comp.types.includes('administrative_area_level_3')) {
                    towns.map((town) => {
                      if (town.nome == comp.long_name)
                        setSelectedTown({ value: town.id, label: town.nome+' ('+town.province.sigla+')' });
                    });
                  }
                  setWait(false);
                });
              } else {
                setWait(false);
                Swal.fire({
                  icon: 'error',
                  title: 'Errore',
                  text: "Non è stato possibile trovare l'indirizzo"
                });
              }
            });
          });
        });
      });
    } else {
      setWait(false);
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Devi inserire le coordinate per poter trovare l'indirizzo"
      });
    }
  }

  const handleSaveDraft = () => {
    setDraft(1);
    handleSubmit((data) => onSubmit(data, 1))();
  }

  const handleSaveDef = () => {
    setDraft(0);
    handleSubmit((data) => onSubmit(data, 0))();
  }

  // Submit della form
  const onSubmit = (form_data, tmp_draft) => {
    setWait(true);
    console.log("Sto salvando la pratica");
    console.log(form_data);

    let obbl_ok = true;
    // Validazione dati obbligatori (non relativi ai beni)
    if (!selectedCustomer)
      obbl_ok = false;
    if (!form_data.evaluation.id_commessa)
      obbl_ok = false;
    if (!form_data.evaluation.evaluation_type_id)
      obbl_ok = false;
    if (!form_data.evaluation.province_id)
      obbl_ok = false;
    if (!selectedTown)
      obbl_ok = false;
    if (!form_data.evaluation.indirizzo)
      obbl_ok = false;
    if (selectedType && !['Desktop','DriveBy'].includes(selectedType)) {
      if (!form_data.evaluation.sopralluogo_referente)
        obbl_ok = false;
      if (!form_data.evaluation.sopralluogo_telefono_1)
        obbl_ok = false;
    }
    if (obbl_ok) {
      form_data['evaluation']['user_id'] = selectedCustomer.value;
      if (selectedProject)
        form_data['evaluation']['project_id'] = selectedProject.value;
      if (selectedTown)
        form_data['evaluation']['town_id'] = selectedTown.value;
      if (selectedBillingCustomer)
        form_data['evaluation']['billing_user_id'] = selectedBillingCustomer.value;

      let tmp_assets = [];
      let assets_ok = true;
      // Inserisco nell'elenco dei beni i FABBRICATI
      if (form_data.f_assets && form_data.f_assets.length) {
        form_data.f_assets.forEach((ass) => {
          let tmp_ass = ass;
          if (!tmp_ass.town_id || !tmp_ass.foglio || !tmp_ass.mappale)
            assets_ok = false;
          tmp_ass.parcel_type_id = 1; // Tipologia: Fabbricato
          if (!tmp_ass.subalterno)
            tmp_ass.subalterno = '0';
          // tmp_ass.registry_category_id = 1; // per convenzione metto la cat. "A1" su tutti i fabbricati
          tmp_assets.push(tmp_ass);
        });
      }
      // Inserisco nell'elenco dei beni i TERRENI
      if (form_data.t_assets && form_data.t_assets.length) {
        form_data.t_assets.forEach((ass) => {
          let tmp_ass = ass;
          if (!tmp_ass.town_id || !tmp_ass.foglio || !tmp_ass.mappale)
            assets_ok = false;
          tmp_ass.parcel_type_id = 2; // Tipologia: Terreno
          // tmp_ass.registry_category_id = 53;  // assegno la cat. "T"
          tmp_assets.push(tmp_ass);
        });
      }
      if (tmp_assets.length)
        form_data['assets'] = tmp_assets;
      // Aggiungo il parametro Draft
      form_data['evaluation']['bozza'] = tmp_draft.toString();
      // Controllo validazione sugli asset (se non ci sono dà comunque TRUE)
      if (assets_ok) {
        axios.post(window.SERVER_URL+'evaluations/save', form_data, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token,
            // 'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          if (res.data.success) {
            setWait(false);
            Swal.fire({
              html: `<img src="${process.env.PUBLIC_URL}/gfx/Raggruppa138.png"><br><b>Ottimo lavoro!</b><br>La pratica con ID ${res.data.evaluation_id}<br>è stata inserita con successo.`,
              // showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: "Carica nuova pratica",
              cancelButtonText: "Torna alla dashboard",
              // denyButtonText: "Assegna tecnico",
              confirmButtonColor: "#12275d",
              cancelButtonColor: "#12275d",
              // denyButtonColor: "#12275d"
            }).then((result) => {
              if (result.isConfirmed) { // carica nuova pratica
                navigate('/evaluations', { state: { create_new: 1 } });
              } else if (result.isDenied) {   // salva e rimani - assegna tecnico
                setWait(true);
                
                if (0) {
                  axios.post(window.SERVER_URL+'evaluations/get', { id: res.data.evaluation_id }, { headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+authobj.token
                  }}).then(res => {
                    setEvaluation(res.data.evaluation);
                    setValue("id", res.data.evaluation.id);
        
                    // Setto i campi dei beni (assets)
                    f_remove();
                    t_remove();
                    if (res.data.evaluation && res.data.evaluation.assets && res.data.evaluation.assets.length) {
                      res.data.evaluation.assets.map((item, index) => {
                        if (item.parcel_type.sigla == 'F')
                          f_insert(index, item);
                        else
                          t_insert(index, item);
                      });
                    }

                    // Carico i tecnici
                    axios.post(window.SERVER_URL+'technicians/list', { params: { evaluation_id: res.data.evaluation.id } }, { headers: {
                      'Access-Control-Allow-Origin': '*',
                      'Authorization': 'Bearer '+authobj.token
                    }}).then(rest => {
                      setTechnicians(rest.data.technicians);

                      setWait(false);
                    });
                  });
                }

                navigate('/evaluations/ass', { state: { evaluation_id: form_data.id } });
              } else if (result.isDismissed) {  // torna alla dashboard
                navigate('/evaluations');
              } 
            });
          } else {
            setWait(false);
            Swal.fire({
              icon: 'error',
              title: 'Errore',
              text: "Non è stato possibile salvare la pratica 1"
            });
          }
        }).catch(function(error) {
          console.log(error);
          setWait(false);
          Swal.fire({
            icon: 'error',
            title: 'Errore',
            text: "Non è stato possibile salvare la pratica 2"
          });
        });
      } else {  // validazione asset non è andata a buon fine
        setWait(false);
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: "Non hai caricato tutti i valori obbligatori per i beni"
        });
      }
    } else {
      setWait(false);
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Non hai inserito tutti i campi obbligatori per la pratica"
      })
    }
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className={'content '+(selectedType ? selectedType.replace(/\s+/g, '_').toLowerCase() : '')}>
        <Row className="page_title">
          <Col>
            <h1 style={{'textAlign':'left'}}>Modulo caricamento nuova pratica</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper det_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" name="id" {...register("id")} value={ evaluation ? evaluation.id : null } />
              <div>
                {/* DATI GENERALI DELLA PRATICA */}
                <Row><Col><label className='step_label big'>Dati generali della pratica</label></Col></Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col>
                      <label style={{'marginBottom':'18px'}}>Cliente</label>
                      <Select value={selectedCustomer} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="evaluation[user_id]" options={selectCustomers} onChange={(e) => createEvaluation(e)} />
                    </Col>
                    <Col>
                      <label style={{'marginBottom':'18px'}}>Commessa/Progetto</label>
                      <Select value={selectedProject} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="evaluation[project_id]" options={selectProjects} onChange={(e) => setSelectedProject(e)} />
                    </Col>
                    <Col>
                      <label>ID Pratica *</label>
                      <input type="text" placeholder="ID Pratica" {...register("evaluation[id_commessa]")} />
                    </Col>
                    <Col>
                      <label>Tipologia pratica *</label>
                      <select {...register("evaluation[evaluation_type_id]")} onChange={(e) => handleChangeType(e)}>
                        <option key="0" value="">- Seleziona la tipologia -</option>
                        { evaluationTypes && evaluationTypes.length ? evaluationTypes.map((type) => {
                        return <option key={ type.id } value={ type.id }>{ type.descrizione }</option>
                      }) : '' }
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <label>Titolare della pratica</label>
                      <input type="text" placeholder="Nome cognome / Ragione sociale" {...register("evaluation[titolare]")} />
                    </Col>
                    <Col>
                      <label>NDG</label>
                      <input type="text" placeholder="Cod. id. int. banca" {...register("evaluation[ndg]")} />
                    </Col>
                    <Col>
                      <label style={{'marginBottom':'20px'}}>Fatturazione a carico di</label><br />
                      <input {...register("evaluation[fatturazione]")} type="radio" value="cliente" className='noMargin' onClick={() => hideFattInfo()} /> Cliente &nbsp;&nbsp;&nbsp;
                      <input {...register("evaluation[fatturazione]")} type="radio" value="titolare" className='noMargin' onClick={() => showFattInfo()} /> Titolare
                    </Col>
                    <Col>
                      <Button id="btn_invoice_info" className={ showBtnInvoiceInfo == 1 || (evaluation && evaluation.fatturazione == 'titolare') ? 'visible' : '' } style={{'marginTop':'40px','width':'280px'}} onClick={() => toggleFattInfo()}>Mostra/nascondi dati fatturazione</Button>
                    </Col>                  
                  </Row>
                </div>

                {/* DATI DI FATTURAZIONE */}
                <div id="invoice_info" className={ showInvoiceInfo == 1 ? 'visible' : '' }>
                  <Row><Col><label className='step_label big'>Dati di fatturazione</label></Col></Row>
                  <div className='inner_wrapper'>
                    <Row>
                      <input type="hidden" name="billing[id]" {...register("billing[id]")} value={ evaluation && evaluation.billing ? evaluation.billing.id : null } />
                      <Col>
                        <label>Ragione sociale *</label>
                        <input type="text" placeholder="Ragione sociale" {...register("billing[ragione_sociale]")} />
                      </Col>
                      <Col>
                        <label>Partita IVA *</label>
                        <input type="text" placeholder="Partita IVA" {...register("billing[partita_iva]")} />
                      </Col>
                      <Col>
                        <label>Codice fiscale *</label>
                        <input type="text" placeholder="Codice fiscale" {...register("billing[codice_fiscale]")} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Comune *</label>
                        <input type="text" placeholder="Comune" {...register("billing[comune]")} />
                      </Col>
                      <Col>
                        <label>Provincia *</label>
                        <select {...register("billing[province_id]")}>
                          <option value="">- Seleziona la provincia -</option>
                          { provinces ? provinces.map((prov) => 
                            <option value={ prov.id }>{ prov.nome }</option>
                          ) : '' }
                        </select>
                      </Col>
                      <Col>
                        <label>Stato *</label>
                        <input type="text" placeholder="Stato" {...register("billing[stato]")} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>CAP *</label>
                        <input type="text" placeholder="CAP" {...register("billing[cap]")} />
                      </Col>
                      <Col>
                        <label>Indirizzo *</label>
                        <input type="text" placeholder="Indirizzo" {...register("billing[indirizzo]")} />
                      </Col>
                      <Col>
                        <label>Num. civico *</label>
                        <input type="text" placeholder="Civico" {...register("billing[civico]")} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>E-mail</label>
                        <input type="email" placeholder="E-mail" {...register("billing[email]")} />
                      </Col>
                      <Col>
                        <label>Telefono</label>
                        <input type="text" placeholder="Telefono" {...register("billing[telefono]")} />
                      </Col>
                      <Col>
                        <label>SDI</label>
                        <input type="text" placeholder="SDI" {...register("billing[sdi]")} />
                      </Col>
                    </Row>
                  </div>
                </div>

                {/* CARICAMENTO DOCUMENTI */}
                <Row><Col><label className='step_label big'>Caricamento documenti</label></Col></Row>
                <Row>
                  <Col>
                    <div className='uploader_wrapper'>
                      <FileUploader handleChange={handleFileUpload} name="file" multiple={true} label="TRASCINA E RILASCIA I FILE QUI PER CARICARLI" classes="drop_zone" />
                    </div>
                  </Col>
                </Row>
                <Row style={{'marginTop':'0px'}}>
                  <Col className='tab_wrapper'>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Nome file</th>
                          {/* <th>Peso</th> */}
                          {/* <th>Data caricamento</th> */}
                          <th style={{'width':'120px'}}>Azioni</th>
                        </tr>
                      </thead>
                      <tbody>
                        { evaluationFiles && evaluationFiles.length ? evaluationFiles.map((f) => 
                          <tr>
                            <td>{ f.nome }</td>
                            {/* <td>{ f.peso }</td> */}
                            {/* <td>{ f.data_caricamento }</td> */}
                            <td className="actions">
                              <Button title="Scarica file" onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></Button>
                              <Button title="Elimina file" onClick={() => { handleDeleteFile(f.id) }} className="delete"><DynamicIcon iconName='FaTrash' /></Button>
                            </td>
                          </tr>
                        ) : '' }
                      </tbody>
                    </Table>
                  </Col>
                </Row>

                {/* GEOLOCALIZZAZIONE PRINCIPALE */}
                <Row><Col><label className='step_label big'>Geolocalizzazione principale</label></Col></Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col lg={8}>
                      <Row>
                        <Col>
                          <label>Provincia *</label>
                          <select {...register("evaluation[province_id]")} onChange={(e) => handleProvinceChange(e.target.value)}>
                            <option value="">- Seleziona la provincia -</option>
                            { provinces.map((prov) => 
                              <option value={ prov.id }>{ prov.nome }</option>
                            )}
                          </select>
                        </Col>
                        <Col>
                          <label style={{'marginBottom':'18px'}}>Città *</label>
                          <Select value={selectedTown} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="evaluation[town_id]" options={selectTowns} onChange={(e) => handleTownChange(e)} />
                        </Col>
                        <Col>
                          <label>CAP *</label>
                          <select {...register("evaluation[cap]")}>
                            <option value="">- Seleziona il CAP -</option>
                            { postalCodes.map((cap) => 
                              <option value={ cap }>{ cap }</option>
                            )}
                          </select>
                        </Col>
                        <Col>
                          <label>Frazione/Località</label>
                          <input type="text" placeholder="Frazione o località" {...register("evaluation[localita]")} />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={8}>
                          <label>Indirizzo *</label>
                          <input type="text" placeholder="Indirizzo" {...register("evaluation[indirizzo]")} />
                        </Col>
                        <Col>
                          <label>Civico</label>
                          <input type="text" placeholder="Civico" {...register("evaluation[civico]")} />
                        </Col>
                        {/* <Col>
                          <label>Stato *</label>
                          <input type="text" placeholder="Stato" {...register("evaluation[nazione]")} />
                        </Col> */}
                      </Row>
                      <Row>
                        <Col>
                          <Button onClick={() => handleFindAddress()} style={{'marginTop':'20px'}}><DynamicIcon iconName="FaMapMarkerAlt" /> Calcola da coordinate</Button>
                        </Col>
                        <Col>
                          <Button onClick={() => viewAddressOnGoogleMaps()} style={{'marginTop':'20px','float':'right'}}><DynamicIcon iconName="FaExternalLinkAlt" spaced /> Vedi su GMaps</Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} style={{'borderLeft':'1px solid #ddd'}}>
                      <Row>
                        <Col>
                          <label>Latitudine</label>
                          <input type="text" placeholder="Latitudine" {...register("evaluation[lat]")} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Longitudine</label>
                          <input type="text" placeholder="Latitudine" {...register("evaluation[lng]")} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button onClick={() => handleFindCoords()} style={{'marginTop':'20px'}}><DynamicIcon iconName="FaMapMarkerAlt" /> Calcola da indirizzo</Button>
                        </Col>
                        <Col>
                          <Button onClick={() => viewCoordsOnGoogleMaps()} style={{'marginTop':'20px','float':'right'}}><DynamicIcon iconName="FaExternalLinkAlt" spaced /> Vedi su GMaps</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>

                <div className='eval_add_caricamento_beni'>
                  {/* CARICAMENTO TERRENI */}
                  <Row>
                    <Col>
                      <label className='step_label big'>Caricamento terreni <Button onClick={() => add_t_asset() } className='waste_btn tiny'><DynamicIcon iconName='FaPlus' /></Button></label>
                    </Col>
                  </Row>
                  <div className='inner_wrapper'>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th style={{'width':'300px'}}>Comune catastale *</th>
                          {/* <th>Categoria catastale *</th> */}
                          <th>Sezione</th>
                          <th>Foglio *</th>
                          <th>Particella *</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {t_fields.map((item, index) =>
                          <tr key={item.id}>
                            <input type="hidden" {...register(`t_assets.${index}.id`)} />
                            <td style={{'width':'300px'}}>
                              <select {...register(`t_assets.${index}.town_id`)}>
                                <option value="">- Seleziona il comune -</option>
                                { towns && towns.length ? towns.map((t) => 
                                  <option value={t.id}>{ t.nome+' ('+t.province.sigla+')' }</option>
                                ) : '' }
                              </select>
                            </td>
                            {/* <td style={{'width':'300px'}}>
                              <select {...register(`t_assets.${index}.registry_category_id`)}>
                                <option value="">- Seleziona la categoria catastale -</option>
                                { registryCategories.map((cat) => 
                                  cat.descrizione === 'T' ? (
                                    <option key={cat.id} value={cat.id}>
                                      {cat.descrizione}
                                    </option>
                                  ) : null
                                )}
                              </select>
                            </td> */}
                            <td>
                              <input type="text" placeholder="Sezione" {...register(`t_assets.${index}.sezione`)} />
                            </td>
                            <td>
                              <input type="text" placeholder="Foglio" maxLength="4" {...register(`t_assets.${index}.foglio`, { maxLength: 4 })} />
                            </td>
                            <td>
                              <input type="text" placeholder="Particella" maxLength="5" {...register(`t_assets.${index}.mappale`, { maxLength: 5 })} />
                            </td>
                            <td className="actions" style={{'width':'30px','textAlign':'center'}}>
                              <Button className="tiny delete" onClick={() => t_remove(index) }><DynamicIcon iconName='TiDelete' size="26" /></Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                
                <div className='eval_add_caricamento_beni'>
                  {/* CARICAMENTO FABBRICATI */}
                  <Row>
                    <Col>
                      <label className='step_label big'>Caricamento fabbricati <Button onClick={() => add_f_asset() } className='waste_btn tiny'><DynamicIcon iconName='FaPlus' /></Button></label>
                    </Col>
                  </Row>
                  <div className='inner_wrapper'>
                  <Table responsive>
                      <thead>
                        <tr>
                          <th style={{'width':'300px'}}>Comune catastale *</th>
                          {/* <th>Categoria catastale *</th> */}
                          <th>Sezione</th>
                          <th>Foglio *</th>
                          <th>Particella *</th>
                          <th>Subalterno</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {f_fields.map((item, index) =>
                          <tr key={item.id}>
                            <input type="hidden" {...register(`f_assets.${index}.id`)} />
                            <td style={{'width':'300px'}}>
                              <select {...register(`f_assets.${index}.town_id`)}>
                                <option value="">- Seleziona il comune -</option>
                                { towns && towns.length ? towns.map((t) => 
                                  <option value={t.id}>{ t.nome+' ('+t.province.sigla+')' }</option>
                                ) : '' }
                              </select>
                            </td>
                            {/* <td style={{'width':'300px'}}>
                              <select {...register(`f_assets.${index}.registry_category_id`)}>
                                <option value="">- Seleziona la categoria catastale -</option>
                                { registryCategories.map((cat) => (
                                  cat.descrizione != 'T' ?
                                    <option value={ cat.id }>{ cat.descrizione }</option>
                                  : ''
                                ))}
                              </select>
                            </td> */}
                            <td>
                              <input type="text" placeholder="Sezione" {...register(`f_assets.${index}.sezione`)} />
                            </td>
                            <td>
                              <input type="text" placeholder="Foglio" maxLength="4" {...register(`f_assets.${index}.foglio`, { maxLength: 4 })} />
                            </td>
                            <td>
                              <input type="text" placeholder="Particella" maxLength="5" {...register(`f_assets.${index}.mappale`, { maxLength: 5 })} />
                            </td>
                            <td>
                              <input type="number" step="1" placeholder="Subalterno" {...register(`f_assets.${index}.subalterno`)} />
                            </td>
                            <td className="actions" style={{'width':'30px','textAlign':'center'}}>
                              <Button className="tiny delete" onClick={() => f_remove(index) }><DynamicIcon iconName='TiDelete' size="26" /></Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>

                {/* DATI CONTATTO PER SOPRALLUOGO */}
                <div id="eval_add_sopralluogo">
                  <Row><Col><label className='step_label big'>Dati contatto per sopralluogo</label></Col></Row>
                  <div className='inner_wrapper'>
                    <Row>
                      <Col>
                        <label>Referente per sopralluogo { !['Desktop','DriveBy'].includes(selectedType) ? '*' : ''}</label>
                        <input type="text" placeholder="Nome e cognome" {...register("evaluation[sopralluogo_referente]")} />
                      </Col>
                      <Col>
                        <label>Telefono 1 { !['Desktop','DriveBy'].includes(selectedType) ? '*' : ''}</label>
                        <input type="number" step="1" placeholder="Telefono 1" {...register("evaluation[sopralluogo_telefono_1]")} />
                      </Col>
                      <Col>
                        <label>Telefono 2</label>
                        <input type="number" step="1" placeholder="Telefono 2" {...register("evaluation[sopralluogo_telefono_2]")} />
                      </Col>
                      <Col>
                        <label>E-mail</label>
                        <input type="email" placeholder="E-mail referente" {...register("evaluation[sopralluogo_email]")} />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              { false ? 
                <>
                  {/* ASSEGNAZIONE TECNICO */}
                  { evaluation && ['ingresso','assegnata'].includes(evaluation.stato.slug) ? 
                    <>
                      { evaluation && evaluation.stato.slug == 'assegnata' ?
                        <Row><Col>
                          <Button id="btn_tech_info" className='waste_btn mini' style={{'display':'inline-block','width':'240px'}} onClick={() => toggleTechInfo()}>Mostra/nascondi lista tecnici</Button>
                        </Col></Row>
                      : '' }
                      { evaluation && evaluation.technician_id && evaluation.technician ?
                        <p style={{'margin':'10px 20px'}}><b>Attualmente assegnata al tecnico:</b> { evaluation.technician.nome+' '+evaluation.technician.cognome }</p>
                      : '' }
                      {/* <div id="tech_info" style={{'marginTop':'10px'}} className={ showTechInfo == 1 ? 'visible' : '' }> */}
                      <div id="tech_info" style={{'marginTop':'10px','display':(showTechInfo == 0 && (!evaluation || evaluation.stato.slug != 'ingresso') ? 'none' : 'block')}}>
                        <label className='step_label big'>Assegna a un tecnico</label>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th></th>
                              <th>Nome</th>
                              <th>Sopralluoghi<br/><b>Non fissati</b></th>
                              <th>Sopralluoghi<br/><b>Fissati</b></th>
                              <th>Sopralluoghi<br/><b>Effettuati</b></th>
                              <th>Incarichi<br/><b>Sospesi</b></th>
                              <th>Incarichi<br/><b>Chiusi</b></th>
                              <th>Incarichi<br/><b>In ritardo</b></th>
                              <th style={{'width':'120px'}}>Dettaglio</th>
                            </tr>
                          </thead>
                          <tbody>
                            { technicians && technicians.length ? technicians.map((tech) => 
                              <tr>
                                <td><input {...register("evaluation[technician_id]")} type="radio" value={tech.id} className='noMargin' /></td>
                                <td>{ tech.nome } { tech.cognome }</td>
                                <td><b>{ tech.widgets ? tech.widgets.assegnate : '' }</b></td>
                                <td><b>{ tech.widgets ? tech.widgets.sopralluoghi_fissati : '' }</b></td>
                                <td><b>{ tech.widgets ? tech.widgets.sopralluoghi_effettuati : '' }</b></td>
                                <td><b>{ tech.widgets ? tech.widgets.sospese : '' }</b></td>
                                <td><b>{ tech.widgets ? tech.widgets.completate : '' }</b></td>
                                <td style={{'color':'#d90000'}}><b>{ tech.widgets ? tech.widgets.ritardo : '' }</b></td>
                                <td className="actions">
                                  <Button title="Vedi dettaglio tecnico" onClick={() => { navigate('/technicians/add', {state: { technician_id: tech.id }}) }}><DynamicIcon iconName="FaEye" /></Button>
                                </td>
                              </tr>
                            ) : '' }
                          </tbody>
                        </Table>
                      </div>
                    </>
                  : <p style={{'margin':'10px 20px'}}><DynamicIcon iconName="FaInfoCircle" /> <b>Per assegnare a un tecnico compila i campi, poi salva e scegli "assegna tecnico"</b></p> }
                </>
              : '' }

              {/* <Row>
                <Col>
                  <Button type="submit" variant="primary" className="waste_btn">Salva pratica</Button>
                </Col>
              </Row> */}

            </form>
            <Row>
              <Col style={{'textAlign':'center'}}>
                <Button onClick={() => handleSaveDraft()}>
                  <DynamicIcon iconName='FaSave' size="22" spaced /> Salva bozza
                </Button>
                <Button onClick={() => handleSaveDef()} style={{'marginLeft':'20px'}}>
                  <DynamicIcon iconName='FaCheck' size="22" spaced /> Conferma e salva
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <br /><hr />
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => { navigate('/evaluations', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } }); }}>
              <DynamicIcon iconName='IoIosArrowBack' /> Torna alle pratiche
            </Button> 
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default EvaluationsAdd;